import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Divider, Icon, Layout, LegacyCard, Page, Text, Link, Toast, AppProvider } from '@shopify/polaris';
import { themeIcon, emailIcon, ProductIcon, ProductAvailableIcon, customCssIcon } from '../components/commonSvg';
import { ApiCall } from '../helper/axios';
import { useSelector, useDispatch } from 'react-redux';
import { storeData } from '../store/storeSlice';
import { Provider as AppBridgeProvider, TitleBar } from "@shopify/app-bridge-react";
import { config } from '../helper/commonApi';
import Footer from './Footer';

const Setting = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const clientStoreData = useSelector(state => state.clientStoreData);
    const storeInfo = clientStoreData.clientInfo?.storeInfo;
    const header = { authentication: storeInfo?.token };
    const shop = storeInfo?.shop_data?.store_name
    const app_status = storeInfo?.shop_data?.app_status;
    const [appStatus, setAppStatus] = useState(app_status);
    const [activeEnable, setActiveEnable] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (app_status !== undefined) {
            setAppStatus((app_status === "0" ? true : false))
        }
    }, [app_status]);

    const toggleActive = useCallback(
        () => setActiveEnable((activeEnable) => !activeEnable),
        []
    );

    const toastMarkup = activeEnable ? (
        <Toast
            duration={5000}
            content={appStatus ? "Disable Successfully" : "Enable Successfully"}
            onDismiss={toggleActive}
        />
    ) : null;

    const manageAppstatus = async (status) => {
        setLoading(true);
        const url = `https://${shop}/admin/themes/current/editor?context=apps&activateAppId=503efd8f-a33b-4d35-aaf8-0b15bfc54246/backinstock`;
        window.open(url, "_blank");

        const data = {
            store_client_id: storeInfo?.shop_data?.store_client_id,
            status
        };
        const res = await ApiCall('PUT', '/manage_appstatus', data, header);
        if (res?.data?.statusCode === 200) {
            let shop_Data = Object.assign({}, storeInfo.shop_data);
            shop_Data.app_status = appStatus ? "1" : "0";
            const shopInfo = {
                "shop_data": shop_Data,
                "token": storeInfo?.token,
            };
            setAppStatus(!appStatus);
            dispatch(storeData(shopInfo));
            toggleActive();
            setLoading(false);
        }
    }
    return (
        <>
            {window.location.hostname !== "localhost" ? <>
                <AppProvider>
                    <AppBridgeProvider config={config}>
                        <TitleBar title={"Settings"} />
                        <Page>
                            <Layout>
                                <Layout.AnnotatedSection id="storeDetails" title="Application status" description="Enable or disable app on your store.">
                                    <LegacyCard sectioned>
                                        <div className='setting_button'>
                                            <p>View a summary of your online store’s performance.</p>
                                            {appStatus ? (
                                                <Button primary onClick={() => manageAppstatus(1)} loading={loading}>Enable</Button>
                                            ) : (
                                                <Button destructive onClick={() => manageAppstatus(0)} loading={loading}>Disable</Button>
                                            )}
                                        </div>
                                    </LegacyCard>
                                </Layout.AnnotatedSection>
                                <Layout.Section>
                                    <Divider />
                                </Layout.Section>
                                <Layout.Section>
                                    <LegacyCard sectioned>
                                        <div className='setting-items'>
                                            <div className='setting-main-item'>
                                                <Link monochrome onClick={() => { navigate('/setting-theme') }}>
                                                    <div className='theme_icon'>
                                                        <div className='theme_icon_div'>
                                                            <Icon
                                                                source={themeIcon}
                                                                color="base"
                                                            />
                                                        </div>
                                                        <div>
                                                            <Text variant="headingSm" as="h6">Theme</Text>
                                                            <p className='setting-helptext'>
                                                                View and update your store theme
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='setting-main-item'>
                                                <Link monochrome onClick={() => { navigate('/setting-product-available') }}>
                                                    <div className='theme_icon'>
                                                        <div className='theme_icon_div'>
                                                            <Icon
                                                                source={ProductAvailableIcon}
                                                                color="base"
                                                            />
                                                        </div>
                                                        <div >
                                                            <Text variant="headingSm" as="h6">Product available</Text>
                                                            <p className='setting-helptext'>
                                                                Customize your product available
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='setting-main-item'>
                                                <Link monochrome onClick={() => { navigate('/setting-email') }}>
                                                    <div className='theme_icon'>
                                                        <div className='theme_icon_div'>
                                                            <Icon
                                                                source={emailIcon}
                                                                color="base"
                                                            />
                                                        </div>
                                                        <div >
                                                            <Text variant="headingSm" as="h6">Email template</Text>
                                                            <p className='setting-helptext'>
                                                                Manage settings for email template
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='setting-main-item'>
                                                <Link monochrome onClick={() => { navigate('/setting-customcss') }}>
                                                    <div className='theme_icon'>
                                                        <div className='theme_icon_div'>
                                                            <Icon
                                                                source={customCssIcon}
                                                                color="base"
                                                            />
                                                        </div>
                                                        <div >
                                                            <Text variant="headingSm" as="h6">Custom css</Text>
                                                            <p className='setting-helptext'>
                                                                Need text change
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='setting-main-item'>
                                                <Link monochrome onClick={() => { navigate('/setting-product') }}>
                                                    <div className='theme_icon'>
                                                        <div className='theme_icon_div'>
                                                            <Icon
                                                                source={ProductIcon}
                                                                color="base"
                                                            />
                                                        </div>
                                                        <div >
                                                            <Text variant="headingSm" as="h6">Product page</Text>
                                                            <p className='setting-helptext'>
                                                                Manage settings for product page form
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </LegacyCard>
                                </Layout.Section>
                                {toastMarkup}
                            </Layout>
                            <Footer />
                        </Page>
                    </AppBridgeProvider>
                </AppProvider>
            </> : <>
                <Page>
                    <Layout>
                        <Layout.AnnotatedSection id="storeDetails" title="Application status" description="Enable or disable app on your store.">
                            <LegacyCard sectioned>
                                <div className='setting_button'>
                                    <p>View a summary of your online store’s performance.</p>
                                    {appStatus ? (
                                        <Button primary onClick={() => manageAppstatus(1)} loading={loading}>Enable</Button>
                                    ) : (
                                        <Button destructive onClick={() => manageAppstatus(0)} loading={loading}>Disable</Button>
                                    )}
                                </div>
                            </LegacyCard>
                        </Layout.AnnotatedSection>
                        <Layout.Section>
                            <Divider />
                        </Layout.Section>
                        <Layout.Section>
                            <LegacyCard sectioned>
                                <div className='setting-items'>
                                    <div className='setting-main-item'>
                                        <Link monochrome onClick={() => { navigate('/setting-theme') }}>
                                            <div className='theme_icon'>
                                                <div className='theme_icon_div'>
                                                    <Icon
                                                        source={themeIcon}
                                                        color="base"
                                                    />
                                                </div>
                                                <div>
                                                    <Text variant="headingSm" as="h6">Theme</Text>
                                                    <p className='setting-helptext'>
                                                        View and update your store theme
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='setting-main-item'>
                                        <Link monochrome onClick={() => { navigate('/setting-product-available') }}>
                                            <div className='theme_icon'>
                                                <div className='theme_icon_div'>
                                                    <Icon
                                                        source={ProductAvailableIcon}
                                                        color="base"
                                                    />
                                                </div>
                                                <div >
                                                    <Text variant="headingSm" as="h6">Product available</Text>
                                                    <p className='setting-helptext'>
                                                        Customize your product available
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='setting-main-item'>
                                        <Link monochrome onClick={() => { navigate('/setting-email') }}>
                                            <div className='theme_icon'>
                                                <div className='theme_icon_div'>
                                                    <Icon
                                                        source={emailIcon}
                                                        color="base"
                                                    />
                                                </div>
                                                <div >
                                                    <Text variant="headingSm" as="h6">Email template</Text>
                                                    <p className='setting-helptext'>
                                                        Manage settings for email template
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='setting-main-item'>
                                        <Link monochrome onClick={() => { navigate('/setting-customcss') }}>
                                            <div className='theme_icon'>
                                                <div className='theme_icon_div'>
                                                    <Icon
                                                        source={customCssIcon}
                                                        color="base"
                                                    />
                                                </div>
                                                <div >
                                                    <Text variant="headingSm" as="h6">Custom css</Text>
                                                    <p className='setting-helptext'>
                                                        Need text change
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='setting-main-item'>
                                        <Link monochrome onClick={() => { navigate('/setting-product') }}>
                                            <div className='theme_icon'>
                                                <div className='theme_icon_div'>
                                                    <Icon
                                                        source={ProductIcon}
                                                        color="base"
                                                    />
                                                </div>
                                                <div >
                                                    <Text variant="headingSm" as="h6">Product page</Text>
                                                    <p className='setting-helptext'>
                                                        Manage settings for product page form
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </LegacyCard>
                        </Layout.Section>
                        {toastMarkup}
                    </Layout>
                    <Footer />
                </Page>
            </>}
        </>
    )
}

export default Setting