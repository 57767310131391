import React, { useEffect } from 'react';
import Router from './router/Router';
// import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Provider, NavigationMenu } from '@shopify/app-bridge-react';
import { Spinner } from '@shopify/polaris';
import { config } from './helper/commonApi';
import { GenerateToken } from './store/storeSlice';

const App = () => {
  // const app_location = useLocation();
  // console.log(app_location);
  
  const dispatch = useDispatch();
  const clientStoreData = useSelector(state => state?.clientStoreData);
  const { status, clientInfo } = clientStoreData;

  useEffect(() => {
    dispatch(GenerateToken());
  }, []);

  if (clientInfo && status) {
    if (window.location.hostname === "localhost") {
      return <Router />;
    } else {
      return (
        <>
          {/* <meta name="shopify-api-key" content="%eaa7ec2c444e96fad842c126a29e929e%" />
      <meta name="shopify-api-key" content="%hiralavaiya.myshopify.com%" />
      <script src="https://cdn.shopify.com/shopifycloud/app-bridge.js"></script>
      <NavMenu>
        <a href="/product" rel="Product">Product</a>
        <a href="/Customer">Customer</a>
        <a href="/settings">Settings</a>
        <a href="/pricing_plan">Pricing plan</a>
      </NavMenu> */}
          <Provider config={config}>
            <NavigationMenu
              navigationLinks={[
                {
                  label: "Product",
                  destination: "/product",
                },
                {
                  label: "Customer",
                  destination: "/Customer",
                },
                {
                  label: "Settings",
                  destination: "/setting",
                },
                {
                  label: "Pricing plan",
                  destination: "/pricing_plan",
                },
                {
                  label: "Request a Feature",
                  destination: "/feature_req",
                },
              ]}
              matcher={(link, location) =>
                link.destination === location.pathname
              }
            />
          </Provider>
          <Router />
        </>
      );
    }
  } else if(!status){    
    return <><div className='main-spinner'><Spinner accessibilityLabel="Spinner example" size="large" /></div></>;
  }
}

export default App;
