import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Button, FormLayout, LegacyCard, Page, PageActions, TextField, Toast, AppProvider } from '@shopify/polaris';
import { ApiCall, GetApiCall } from '../helper/axios';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { emailData } from '../store/emailSlice';
import {
    Provider as AppBridgeProvider,
    TitleBar,
} from "@shopify/app-bridge-react";
import { config } from "../helper/commonApi";
import Footer from './Footer';

const EditSettingEmail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const clientStoreData = useSelector(state => state.clientStoreData);
    const storeInfo = clientStoreData.clientInfo?.storeInfo;
    const shop_name = storeInfo?.shop_data?.shop_name;
    const email = storeInfo?.shop_data?.email;
    const from_email = `${shop_name} <${email}>`;
    const [activeEnable, setActiveEnable] = useState(false);
    const [loading, setLoading] = useState(false);
    const header = { authentication: storeInfo?.token };
    const [toastMsg, setToastMsg] = useState('');
    const [emailHtml, setEmailhtml] = useState('');
    const [emailDesign, setEmaildesign] = useState('');

    const toggleActive = useCallback(
        () => setActiveEnable((activeEnable) => !activeEnable),
        []
    );

    let [initialState, setInitialState] = useState({
        email_sub: '{{product.title}} is now available to order from {{shop.name}}',
        from_email_address: ''
    });

    let formSchema = Yup.object().shape({
        email_sub: Yup.string().required('Subject field is required'),
        from_email_address: Yup.string().required('Form email address field is required'),
    })

    const formik = useFormik({
        initialValues: initialState,
        validationSchema: formSchema,
        enableReinitialize: true,
        onSubmit: () => {
            saveEmail()
        }
    });

    const toastMarkup = activeEnable ? (
        <Toast
            duration={5000}
            content={toastMsg}
            onDismiss={toggleActive}
        />
    ) : null;

    const saveEmail = async () => {
        setLoading(true);
        const data = {
            store_client_id: storeInfo?.shop_data?.store_client_id,
            email_sub: formik.values.email_sub,
            from_email_address: formik.values.from_email_address,
            email_html: btoa(JSON.stringify(emailHtml)),
            email_design: btoa(JSON.stringify(emailDesign)),
        };

        const res = await ApiCall('POST', '/save_email_temp', data, header);
        if (res?.data?.statusCode === 200) {
            setToastMsg(res.data.message);
            toggleActive();
            setLoading(false);
            navigate('/setting-email');
        }
    }

    const getTemplate = async () => {
        const temp_id = (id === undefined) ? 1 : id;
        if (temp_id) {
            let response = await GetApiCall("GET", `/get-emailtemplate?id=${temp_id}`, header);
            if (response?.data?.statusCode === 200) {
                let res = response.data?.data;
                document.getElementById('tempHtml').innerHTML = JSON.parse(atob(res?.email_html));
                setEmailhtml(JSON.parse(atob(res?.email_html)));
                setEmaildesign(JSON.parse(atob(res?.email_design)));
                const item = {
                    email_sub: res?.email_sub,
                    from_email_address: res?.from_email_address
                };
                setInitialState(item);
            }
        }
    }

    useEffect(() => {
        if (storeInfo) {
            getTemplate();
        }
    }, [storeInfo]);

    const editTemplate = () => {
        const data = {
            email_sub: (formik.values.email_sub !== '') ? formik.values.email_sub : '{{product.title}} is now available to order from {{shop.name}}',
            from_email_address: (formik.values.from_email_address !== '') ? formik.values.from_email_address : from_email
        };

        if (id) {
            dispatch(emailData(data));
            navigate(`/edit_email/${id}`);
        } else {
            dispatch(emailData(data));
            navigate(`/edit_email`);
        }
    }
    return (
        <>
            {window.location.hostname !== "localhost" ? <>
                <AppProvider>
                    <AppBridgeProvider config={config}>
                        <TitleBar title={"Email template setting"} breadcrumbs={[{ content: "Email template", onAction: () => navigate("/setting-email") }]} />
                        <Page backAction={{ content: 'Email template setting', onAction: () => navigate('/setting-email') }} title={'Email template setting'}>
                            <LegacyCard>
                                <LegacyCard.Section title='General'>
                                    <p>Manage most important app setting</p>
                                </LegacyCard.Section>
                                <LegacyCard.Section>
                                    <FormLayout>
                                        <TextField
                                            type='text'
                                            label="Subject"
                                            name="email_sub"
                                            value={(formik.values.email_sub !== '') ? formik.values.email_sub : '{{product.title}} is now available to order from {{shop.name}}'}
                                            onChange={(value) => { formik.setFieldValue('email_sub', value) }}
                                            autoComplete="off"
                                            onBlur={() => formik.setFieldTouched('email_sub')}
                                            error={formik.errors.email_sub && formik.touched.email_sub ? formik.errors.email_sub : ''}
                                            helpText='{{product.title}} is now available to order from {{shop.name}}' />
                                        <TextField
                                            type="email"
                                            label="Form email address"
                                            name='from_email_address'
                                            value={(formik.values.from_email_address !== '') ? formik.values.from_email_address : from_email}
                                            onChange={(value) => { formik.setFieldValue('from_email_address', value) }}
                                            autoComplete="email"
                                            onBlur={() => formik.setFieldTouched('from_email_address')}
                                            error={formik.errors.from_email_address && formik.touched.from_email_address ? formik.errors.from_email_address : ''}
                                            helpText='Example: My Shop <hello@example.com>'
                                        />
                                    </FormLayout>
                                </LegacyCard.Section>
                                <LegacyCard.Section>
                                    <div className='preview-email'>
                                        <div className='event-none' id="tempHtml"></div>
                                        <div className='email-edit-btn'>
                                            <Button primary onClick={() => { editTemplate() }}>Edit content</Button>
                                        </div>
                                    </div>
                                </LegacyCard.Section>
                            </LegacyCard>
                            <PageActions
                                primaryAction={{
                                    content: 'Save',
                                    onAction: () => formik.handleSubmit(),
                                    loading: loading
                                }}
                            />
                            {toastMarkup}
                            <Footer />
                        </Page>
                    </AppBridgeProvider>
                </AppProvider>
            </> : <>
                <Page backAction={{ content: 'Email template setting', onAction: () => navigate('/setting-email') }} title={'Email template setting'}>
                    <LegacyCard>
                        <LegacyCard.Section title='General'>
                            <p>Manage most important app setting</p>
                        </LegacyCard.Section>
                        <LegacyCard.Section>
                            <FormLayout>
                                <TextField
                                    type='text'
                                    label="Subject"
                                    name="email_sub"
                                    value={(formik.values.email_sub !== '') ? formik.values.email_sub : '{{product.title}} is now available to order from {{shop.name}}'}
                                    onChange={(value) => { formik.setFieldValue('email_sub', value) }}
                                    autoComplete="off"
                                    onBlur={() => formik.setFieldTouched('email_sub')}
                                    error={formik.errors.email_sub && formik.touched.email_sub ? formik.errors.email_sub : ''}
                                    helpText='{{product.title}} is now available to order from {{shop.name}}' />
                                <TextField
                                    type="email"
                                    label="Form email address"
                                    name='from_email_address'
                                    value={(formik.values.from_email_address !== '') ? formik.values.from_email_address : from_email}
                                    onChange={(value) => { formik.setFieldValue('from_email_address', value) }}
                                    autoComplete="email"
                                    onBlur={() => formik.setFieldTouched('from_email_address')}
                                    error={formik.errors.from_email_address && formik.touched.from_email_address ? formik.errors.from_email_address : ''}
                                    helpText='Example: My Shop <hello@example.com>'
                                />
                            </FormLayout>
                        </LegacyCard.Section>
                        <LegacyCard.Section>
                            <div className='preview-email'>
                                <div className='event-none' id="tempHtml"></div>
                                <div className='email-edit-btn'>
                                    <Button primary onClick={() => { editTemplate() }}>Edit content</Button>
                                </div>
                            </div>
                        </LegacyCard.Section>
                    </LegacyCard>
                    <PageActions
                        primaryAction={{
                            content: 'Save',
                            onAction: () => formik.handleSubmit(),
                            loading: loading
                        }}
                    />
                    {toastMarkup}
                    <Footer />
                </Page>
            </>}
        </>
    )
}

export default EditSettingEmail;