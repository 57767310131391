import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import PolarisColorInput from '../components/PolarisColorInput';
import { useNavigate } from "react-router-dom";
import PreviewImg from '../assets/Images/PreviewImg.jpg';
import { ApiCall, GetApiCall } from '../helper/axios';
import { useSelector } from 'react-redux';
import { Button, ButtonGroup, Grid, Divider, FormLayout, Layout, LegacyCard, Page, PageActions, Select, RadioButton, Tabs, Text, TextField, Toast, AppProvider } from '@shopify/polaris';
import { htmlSpecialCharacterEncode, htmlspecialchars_decode } from '../helper/commonFunction';
import {
    Provider as AppBridgeProvider,
    TitleBar,
} from "@shopify/app-bridge-react";
import { config } from "../helper/commonApi";
import Footer from './Footer';

const SettingProduct = () => {
    const navigate = useNavigate();
    const clientStoreData = useSelector(state => state.clientStoreData);
    const storeInfo = clientStoreData.clientInfo?.storeInfo;
    const [contactContent, setContactContent] = useState('');
    const [activeEnable, setActiveEnable] = useState(false);
    const [loading, setLoading] = useState(false);
    const header = { authentication: storeInfo?.token };
    const [toastMsg, setToastMsg] = useState('');

    const toggleActive = useCallback(
        () => setActiveEnable((activeEnable) => !activeEnable),
        []
    );

    const toastMarkup = activeEnable ? (
        <Toast
            duration={5000}
            content={toastMsg}
            onDismiss={toggleActive}
        />
    ) : null;

    const fontFamilyOptions = [
        { label: 'Century Gothic', value: 'Century Gothic' },
        { label: 'Helvetica', value: 'Helvetica' },
        { label: 'Impact', value: 'Impact' },
        { label: 'Montserrat', value: 'Montserrat' },
        { label: 'Roboto', value: 'Roboto' },
        { label: 'Tomorrow', value: 'Tomorrow' }
    ];
    const textStyleOptions = [
        { label: 'Normal', value: 'normal' },
        { label: 'Bold', value: 'bold' },
        { label: 'Italic', value: 'italic' },
        { label: 'Underline', value: 'underline' }
    ];

    let [initialState, setInitialState] = useState({
        custom_style: 0,
        form_header_text: 'NOTIFY WHEN AVAILABLE',
        form_body_text: 'We will send you a notification as soon as this product is available again',
        form_email_address_label: 'Email address',
        form_button_label: 'Notify Me',
        form_footer_text: "We respect your privacy and don't share your email with anybody",
        close_button_tooltip: 'Close',
        push_notification_label: 'Also notify me via push notification',

        registration_complete: 'Your notification has been registered',
        invalid_email_message: 'Invalid email address',
        provide_email_phone: 'Please Provide email address',

        form_bg_color: '#ffffff',
        form_font_family: 'Helvetica',
        form_border_color: '#EB6464',
        form_border_width: '1',
        form_text_color: '#000',
        form_close_button_color: '#000',
        form_overlay_tint_color: '#000',
        form_button_bg_color: '#ffffff',
        form_button_text_color: '#000',
        form_button_border_width: '1',
        form_success_bg_color: '#DFF0D8',
        form_success_text_color: '#3C763D',
        form_error_bg_color: '#F2DEDE',
        form_error_text_color: '#A94442',
        form_input_border_color: '#333333',
        form_input_border_width: '1',
        form_input_border_radius: '5',

        modal_button_caption: 'NOTIFY WHEN AVAILABLE',
        modal_theme_css_classes: '.product',
        modal_text_size: '15',
        modal_text_style: 'Normal',

        modal_btn_width: '100',
        modal_button_height: '50',
        modal_margin_top: '10',
        modal_margin_bottom: '10',
        modal_margin_left: '10',
        modal_margin_right: '10',
        modal_border_size: '1',
        modal_border_radius: '5',

        btn_bg_color: '#000',
        btn_hover_bg_color: '#EB6464',
        btn_text_color: '#FFFFFF',
        btn_hover_text_color: '#EB6464',
        btn_border_color: '#000',
        btn_hover_border_color: '#dfdfdf',

        product_ava_style: 0
    });

    let formSchema = Yup.object().shape({
        form_header_text: Yup.string().required('Header text field is required'),
        form_body_text: Yup.string().required('Body text field is required'),
        form_email_address_label: Yup.string().required('Email addresss label field is required'),
        form_button_label: Yup.string().required('Button label field is required'),
        form_footer_text: Yup.string().required('Footer text field is required'),
        close_button_tooltip: Yup.string().required('Close button tooltip field is required'),
        // push_notification_label: Yup.string().required('This field is required'),

        registration_complete: Yup.string().required('Registration complete field is required'),
        invalid_email_message: Yup.string().required('Email address invalid field is required'),
        provide_email_phone: Yup.string().required('Provide email address field is required'),

        modal_button_caption: Yup.string().when(
            'custom_style', (custom_style, field) => custom_style === 1 ? field.required('Custom style field is required') : field
        ),
    })

    const formik = useFormik({
        initialValues: initialState,
        validationSchema: formSchema,
        enableReinitialize: true,
        onSubmit: () => {
            saveSettings();
        }
    });

    const [selected, setSelected] = useState(0);
    const [selecte, setSelecte] = useState(0);

    const handleTabChange = useCallback(
        (selectedTabIndex) => setSelected(selectedTabIndex),
        [],
    );
    const handleTabChanged = useCallback(
        (selectedTabIndex) => setSelecte(selectedTabIndex),
        [],
    );
    const tabs = [
        {
            id: 'all-customers-4',
            content: 'Sign-up Form Labels',
            accessibilityLabel: 'All customers',
            panelID: 'all-customers-content-4',
        },
        {
            id: 'Confirmation Message',
            content: 'Confirmation Message',
            panelID: 'accepts-marketing-content-4',
        },
        {
            id: 'Sign-up Form Appearance',
            content: 'Sign-up Form Appearance',
            panelID: 'repeat-customers-content-4',
        },
    ];
    const tab = [
        {
            id: 'all-customers-4',
            content: 'Button label',
            accessibilityLabel: 'All customers',
            panelID: 'all-customers-content-4',
        },
        {
            id: 'Button adjustment',
            content: 'Button adjustment',
            panelID: 'accepts-marketing-content-4',
        },
        {
            id: 'Button appearance',
            content: 'Button appearance',
            panelID: 'repeat-customers-content-4',
        },
        {
            id: 'Sign-up Form Labels',
            content: 'Sign-up Form Labels',
            panelID: 'repeat-customers-content-4',
        },
        {
            id: 'Confirmation Message',
            content: 'Confirmation Message',
            panelID: 'repeat-customers-content-4',
        },
        {
            id: 'Sign-up Form Appearance',
            content: 'Sign-up Form Appearance',
            panelID: 'repeat-customers-content-4',
        },
    ];

    const preview_btn = `.notify-btn-preview button .Polaris-Button__Text{
            font-size: ${formik.values.modal_text_size}px !important;
            ${(formik.values.modal_text_style === "italic") ? `font-style: ${formik.values.modal_text_style}` : ''};
            ${(formik.values.modal_text_style === "normal" || formik.values.modal_text_style === "bold") ? `font-weight: ${formik.values.modal_text_style}` : ''};
            ${(formik.values.modal_text_style === "underline") ? `text-decoration: ${formik.values.modal_text_style}` : ''};
        }
        .notify-btn-preview button{
            width: ${formik.values.modal_btn_width}%;
            height: ${formik.values.modal_button_height}px;
            margin: ${formik.values.modal_margin_top}px ${formik.values.modal_margin_right}px ${formik.values.modal_margin_bottom}px ${formik.values.modal_margin_left}px;
            border: ${formik.values.modal_border_size}px solid ${formik.values.btn_border_color};
            border-radius: ${formik.values.modal_border_radius}px !important;
            background-color: ${formik.values.btn_bg_color};
            color: ${formik.values.btn_text_color};
        }
        .notify-btn-preview button:hover{
            border: ${formik.values.modal_border_size}px solid ${formik.values.btn_hover_border_color};
            background-color: ${formik.values.btn_hover_bg_color};
            color: ${formik.values.btn_hover_text_color};
        }
        .action_form .Polaris-LegacyCard{
            background-color: ${formik.values.form_bg_color};
            font-family: ${formik.values.form_button_text_color};
            color: ${formik.values.form_text_color};
        }
        .preview-btn button{
            background-color: ${formik.values.form_button_bg_color};
            border: ${formik.values.form_button_border_width}px solid;
        }
        .preview-btn button .Polaris-Button__Text{
            color: ${formik.values.form_button_text_color};
        }
        .email-preview .Polaris-TextField__Backdrop{
            border: ${formik.values.form_input_border_width}px solid ${formik.values.form_input_border_color};
            border-radius: ${formik.values.form_input_border_radius}px;
        }
    `;

    const saveSettings = async () => {
        setLoading(true);
        const data = {
            settings: {
                store_client_id: storeInfo?.shop_data?.store_client_id,
                custom_style: formik.values.custom_style,
                form_header_text: htmlSpecialCharacterEncode(formik.values.form_header_text),
                form_body_text: htmlSpecialCharacterEncode(formik.values.form_body_text),
                form_email_address_label: htmlSpecialCharacterEncode(formik.values.form_email_address_label),
                form_button_label: htmlSpecialCharacterEncode(formik.values.form_button_label),
                form_footer_text: htmlSpecialCharacterEncode(formik.values.form_footer_text),
                close_button_tooltip: htmlSpecialCharacterEncode(formik.values.close_button_tooltip),
                push_notification_label: formik.values.push_notification_label,
                registration_complete: htmlSpecialCharacterEncode(formik.values.registration_complete),
                invalid_email_message: htmlSpecialCharacterEncode(formik.values.invalid_email_message),
                provide_email_phone: htmlSpecialCharacterEncode(formik.values.provide_email_phone),
                form_bg_color: formik.values.form_bg_color,
                form_font_family: formik.values.form_font_family,
                form_border_color: formik.values.form_border_color,
                form_border_width: formik.values.form_border_width,
                form_text_color: formik.values.form_text_color,
                form_close_button_color: formik.values.form_close_button_color,
                form_overlay_tint_color: formik.values.form_overlay_tint_color,
                form_button_bg_color: formik.values.form_button_bg_color,
                form_button_text_color: formik.values.form_button_text_color,
                form_button_border_width: formik.values.form_button_border_width,
                form_success_bg_color: formik.values.form_success_bg_color,
                form_success_text_color: formik.values.form_success_text_color,
                form_error_bg_color: formik.values.form_error_bg_color,
                form_error_text_color: formik.values.form_error_text_color,
                form_input_border_color: formik.values.form_input_border_color,
                form_input_border_width: formik.values.form_input_border_width,
                form_input_border_radius: formik.values.form_input_border_radius,
                modal_button_caption: htmlSpecialCharacterEncode(formik.values.modal_button_caption),
                modal_theme_css_classes: formik.values.modal_theme_css_classes,
                modal_text_size: formik.values.modal_text_size,
                modal_text_style: formik.values.modal_text_style,
                modal_btntext_size: formik.values.modal_btntext_size,
                modal_btn_width: formik.values.modal_btn_width,
                modal_button_height: formik.values.modal_button_height,
                modal_margin_top: formik.values.modal_margin_top,
                modal_margin_bottom: formik.values.modal_margin_bottom,
                modal_margin_left: formik.values.modal_margin_left,
                modal_margin_right: formik.values.modal_margin_right,
                modal_border_size: formik.values.modal_border_size,
                modal_border_radius: formik.values.modal_border_radius,
                btn_bg_color: formik.values.btn_bg_color,
                btn_hover_bg_color: formik.values.btn_hover_bg_color,
                btn_text_color: formik.values.btn_text_color,
                btn_hover_text_color: formik.values.btn_hover_text_color,
                btn_border_color: formik.values.btn_border_color,
                btn_hover_border_color: formik.values.btn_hover_border_color,
                product_ava_style: formik.values.product_ava_style,
            }
        };
        const res = await ApiCall('POST', '/save_prosettings', data, header);
        if (res?.data?.statusCode === 200) {
            setToastMsg(res.data.message);
            toggleActive();
            setLoading(false);
            // navigate('/setting');
        }
    }

    const getSettings = async () => {
        const res = await GetApiCall('GET', '/get_prosettings', header);
        if (res?.data?.statusCode === 200) {
            const settings = res.data.data;
            const final_data = {};
            final_data.custom_style = parseInt(settings.custom_style);
            final_data.form_header_text = htmlspecialchars_decode(settings.form_header_text);
            final_data.form_body_text = htmlspecialchars_decode(settings.form_body_text);
            final_data.form_email_address_label = htmlspecialchars_decode(settings.form_email_address_label);
            final_data.form_button_label = htmlspecialchars_decode(settings.form_button_label);
            final_data.form_footer_text = htmlspecialchars_decode(settings.form_footer_text);
            final_data.close_button_tooltip = htmlspecialchars_decode(settings.close_button_tooltip);
            final_data.push_notification_label = settings.push_notification_label;
            final_data.registration_complete = htmlspecialchars_decode(settings.registration_complete);
            final_data.invalid_email_message = htmlspecialchars_decode(settings.invalid_email_message);
            final_data.provide_email_phone = htmlspecialchars_decode(settings.provide_email_phone);
            final_data.form_bg_color = settings.form_bg_color;
            final_data.form_font_family = settings.form_font_family;
            final_data.form_border_color = settings.form_border_color;
            final_data.form_border_width = settings.form_border_width;
            final_data.form_text_color = settings.form_text_color;
            final_data.form_close_button_color = settings.form_close_button_color;
            final_data.form_overlay_tint_color = settings.form_overlay_tint_color;
            final_data.form_button_bg_color = settings.form_button_bg_color;
            final_data.form_button_text_color = settings.form_button_text_color;
            final_data.form_button_border_width = settings.form_button_border_width;
            final_data.form_success_bg_color = settings.form_success_bg_color;
            final_data.form_success_text_color = settings.form_success_text_color;
            final_data.form_error_bg_color = settings.form_error_bg_color;
            final_data.form_error_text_color = settings.form_error_text_color;
            final_data.form_input_border_color = settings.form_input_border_color;
            final_data.form_input_border_width = settings.form_input_border_width;
            final_data.form_input_border_radius = settings.form_input_border_radius;
            final_data.modal_button_caption = htmlspecialchars_decode(settings.modal_button_caption);
            final_data.modal_theme_css_classes = settings.modal_theme_css_classes;
            final_data.modal_text_size = settings.modal_text_size;
            final_data.modal_text_style = settings.modal_text_style;
            final_data.modal_btntext_size = settings.modal_btntext_size;
            final_data.modal_btn_width = settings.modal_btn_width;
            final_data.modal_button_height = settings.modal_button_height;
            final_data.modal_margin_top = settings.modal_margin_top;
            final_data.modal_margin_bottom = settings.modal_margin_bottom;
            final_data.modal_margin_left = settings.modal_margin_left;
            final_data.modal_margin_right = settings.modal_margin_right;
            final_data.modal_border_size = settings.modal_border_size;
            final_data.modal_border_radius = settings.modal_border_radius;
            final_data.btn_bg_color = settings.btn_bg_color;
            final_data.btn_hover_bg_color = settings.btn_hover_bg_color;
            final_data.btn_text_color = settings.btn_text_color;
            final_data.btn_hover_text_color = settings.btn_hover_text_color;
            final_data.btn_border_color = settings.btn_border_color;
            final_data.btn_hover_border_color = settings.btn_hover_border_color;
            final_data.product_ava_style = parseInt(settings.product_ava_style);
            setInitialState(final_data);
        }
    }

    useEffect(() => {
        if (storeInfo) {
            getSettings();
        }
    }, [storeInfo]);

    return (
        <>
            {window.location.hostname !== "localhost" ? <>
                <AppProvider>
                    <AppBridgeProvider config={config}>
                        <TitleBar title={"Product page setting"} breadcrumbs={[{ content: "Settings", onAction: () => navigate("/setting") }]} />
                        <Page fullWidth backAction={{ content: 'Products', onAction: () => navigate('/setting') }} title={'Product page setting'}>
                            <Layout>
                                <Layout.Section>
                                    <div className='product_lheader'>
                                        <LegacyCard title='Select your custom style'>
                                            <Divider />
                                            <LegacyCard.Section>
                                                <div className='product_radio'>
                                                    <RadioButton
                                                        label="In-line Form"
                                                        onChange={() => { formik.setFieldValue('custom_style', 0); }}
                                                        id='custom_style_0'
                                                        name="custom_style_0"
                                                        checked={formik.values.custom_style === 0}

                                                    />
                                                    <RadioButton
                                                        label="Modal / Pop-up"
                                                        id="custom_style_1"
                                                        checked={formik.values.custom_style === 1}
                                                        name="custom_style_1"
                                                        onChange={() => { formik.setFieldValue('custom_style', 1); }}
                                                    />
                                                </div>
                                            </LegacyCard.Section>
                                        </LegacyCard>
                                        <LegacyCard title='Personalize your design'>
                                            <Divider />
                                            <Tabs
                                                tabs={formik.values.custom_style === 1 ? tab : tabs}
                                                selected={formik.values.custom_style === 1 ? selected : selecte}
                                                onSelect={formik.values.custom_style === 1 ? handleTabChange : handleTabChanged}
                                                disclosureText="More views"
                                            >
                                                <Divider />
                                                <LegacyCard.Section>
                                                    {(selected === 0 && formik.values.custom_style === 1) && <Grid columns={{ sm: 3 }}>
                                                        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                            <TextField
                                                                type="text"
                                                                label="Button caption"
                                                                name='modal_button_caption'
                                                                value={formik.values.modal_button_caption}
                                                                onChange={(value) => { formik.setFieldValue('modal_button_caption', value); }}
                                                                onBlur={() => formik.setFieldTouched('modal_button_caption')}
                                                                error={formik.errors.modal_button_caption && formik.touched.modal_button_caption ? formik.errors.modal_button_caption : null}
                                                                autoComplete="off"
                                                            />
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                            <TextField
                                                                type="text"
                                                                label="Theme CSS classes (space or comma separated)"
                                                                name='modal_theme_css_classes'
                                                                value={formik.values.modal_theme_css_classes}
                                                                onChange={(value) => { formik.setFieldValue('modal_theme_css_classes', value); }}
                                                                autoComplete="off"
                                                            />
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                            <TextField
                                                                type="number"
                                                                label="Text size"
                                                                min={0}
                                                                name='modal_text_size'
                                                                suffix='px'
                                                                value={formik.values.modal_text_size}
                                                                onChange={(value) => { formik.setFieldValue('modal_text_size', value); }}
                                                                autoComplete="off"
                                                            />
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                            <Select
                                                                label="Text style"
                                                                placeholder='select'
                                                                name='modal_text_style'
                                                                options={textStyleOptions}
                                                                onChange={(value) => { formik.setFieldValue('modal_text_style', value); }}
                                                                value={formik.values.modal_text_style}
                                                            />
                                                        </Grid.Cell>
                                                    </Grid>}
                                                    {(selected === 1 && formik.values.custom_style === 1) && <Grid columns={{ sm: 3 }}>
                                                        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                            <TextField
                                                                type="number"
                                                                label="Button Width"
                                                                min={0}
                                                                suffix='%'
                                                                name='modal_btn_width'
                                                                value={formik.values.modal_btn_width}
                                                                onChange={(value) => { formik.setFieldValue('modal_btn_width', value); }}
                                                                autoComplete="off"
                                                            />
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                            <TextField
                                                                type="number"
                                                                label="Button height"
                                                                min={0}
                                                                suffix='px'
                                                                name='modal_button_height'
                                                                value={formik.values.modal_button_height}
                                                                onChange={(value) => { formik.setFieldValue('modal_button_height', value); }}
                                                                autoComplete="off"
                                                            />
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                            <TextField
                                                                type="number"
                                                                label="Margin top"
                                                                suffix='px'
                                                                name='modal_margin_top'
                                                                value={formik.values.modal_margin_top}
                                                                onChange={(value) => { formik.setFieldValue('modal_margin_top', value); }}
                                                                autoComplete="off"
                                                            />
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                            <TextField
                                                                type="number"
                                                                label="Margin bottom"
                                                                suffix='px'
                                                                name='modal_margin_bottom'
                                                                value={formik.values.modal_margin_bottom}
                                                                onChange={(value) => { formik.setFieldValue('modal_margin_bottom', value); }}
                                                                autoComplete="off"
                                                            />
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                            <TextField
                                                                type="number"
                                                                label="Margin left"
                                                                suffix='px'
                                                                name='modal_margin_left'
                                                                value={formik.values.modal_margin_left}
                                                                onChange={(value) => { formik.setFieldValue('modal_margin_left', value); }}
                                                                autoComplete="off"
                                                            />
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                            <TextField
                                                                type="number"
                                                                label="Margin right"
                                                                suffix='px'
                                                                name='modal_margin_right'
                                                                value={formik.values.modal_margin_right}
                                                                onChange={(value) => { formik.setFieldValue('modal_margin_right', value); }}
                                                                autoComplete="off"
                                                            />
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                            <TextField
                                                                type="number"
                                                                label="Border size"
                                                                min={0}
                                                                suffix='px'
                                                                name='modal_border_size'
                                                                value={formik.values.modal_border_size}
                                                                onChange={(value) => { formik.setFieldValue('modal_border_size', value); }}
                                                                autoComplete="off"
                                                            />
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                            <TextField
                                                                type="number"
                                                                label="Border radius"
                                                                min={0}
                                                                suffix='px'
                                                                name='modal_border_radius'
                                                                value={formik.values.modal_border_radius}
                                                                onChange={(value) => { formik.setFieldValue('modal_border_radius', value); }}
                                                                autoComplete="off"
                                                            />
                                                        </Grid.Cell>
                                                    </Grid>}
                                                    {(selected === 2 && formik.values.custom_style === 1) && <div className='color-settings'>
                                                        <Grid columns={{ sm: 3 }}>
                                                            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                                <PolarisColorInput
                                                                    handlechange={(value) => formik.setFieldValue('btn_bg_color', value)}
                                                                    value={formik.values.btn_bg_color}
                                                                    label={'Background Color'}
                                                                    name={'btn_bg_color'}
                                                                />
                                                            </Grid.Cell>
                                                            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                                <PolarisColorInput
                                                                    handlechange={(value) => formik.setFieldValue('btn_hover_bg_color', value)}
                                                                    value={formik.values.btn_hover_bg_color}
                                                                    label={'Hover Background Color'}
                                                                    name={'btn_hover_bg_color'}
                                                                />
                                                            </Grid.Cell>
                                                            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                                <PolarisColorInput
                                                                    handlechange={(value) => formik.setFieldValue('btn_text_color', value)}
                                                                    value={formik.values.btn_text_color}
                                                                    label={'Text Color'}
                                                                    name={'btn_text_color'}
                                                                />
                                                            </Grid.Cell>
                                                            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                                <PolarisColorInput
                                                                    handlechange={(value) => formik.setFieldValue('btn_hover_text_color', value)}
                                                                    value={formik.values.btn_hover_text_color}
                                                                    label={'Hover Text Color'}
                                                                    name={'btn_hover_text_color'}
                                                                />
                                                            </Grid.Cell>
                                                            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                                <PolarisColorInput
                                                                    handlechange={(value) => formik.setFieldValue('btn_border_color', value)}
                                                                    value={formik.values.btn_border_color}
                                                                    label={'Border Color'}
                                                                    name={'btn_border_color'}
                                                                />
                                                            </Grid.Cell>
                                                            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                                <PolarisColorInput
                                                                    handlechange={(value) => formik.setFieldValue('btn_hover_border_color', value)}
                                                                    value={formik.values.btn_hover_border_color}
                                                                    label={'Hover Border Color'}
                                                                    name={'btn_hover_border_color'}
                                                                />
                                                            </Grid.Cell>
                                                        </Grid>
                                                    </div>}
                                                    {(((selecte === 0) && (formik.values.custom_style === 0)) || (selected === 3 && formik.values.custom_style === 1)) && <Grid columns={{ sm: 3 }}>
                                                        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                            <TextField
                                                                type="text"
                                                                label="Header Text"
                                                                name='form_header_text'
                                                                value={formik.values.form_header_text}
                                                                onChange={(value) => formik.setFieldValue('form_header_text', value)}
                                                                onBlur={() => formik.setFieldTouched('form_header_text')}
                                                                error={formik.errors.form_header_text && formik.touched.form_header_text ? formik.errors.form_header_text : null}
                                                                autoComplete="off"
                                                            />
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                            <TextField
                                                                type="text"
                                                                label="Body Text"
                                                                multiline={2}
                                                                name='form_body_text'
                                                                value={formik.values.form_body_text}
                                                                onChange={(value) => formik.setFieldValue('form_body_text', value)}
                                                                onBlur={() => formik.setFieldTouched('form_body_text')}
                                                                error={formik.errors.form_body_text && formik.touched.form_body_text ? formik.errors.form_body_text : null}
                                                                autoComplete="off"
                                                            />
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                            <TextField
                                                                type="text"
                                                                label="Email Addresss Label"
                                                                name='form_email_address_label'
                                                                value={formik.values.form_email_address_label}
                                                                onChange={(value) => formik.setFieldValue('form_email_address_label', value)}
                                                                onBlur={() => formik.setFieldTouched('form_email_address_label')}
                                                                error={formik.errors.form_email_address_label && formik.touched.form_email_address_label ? formik.errors.form_email_address_label : null}
                                                                autoComplete="off"
                                                            />
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                            <TextField
                                                                type="text"
                                                                label="Button Label"
                                                                name='form_button_label'
                                                                value={formik.values.form_button_label}
                                                                onChange={(value) => formik.setFieldValue('form_button_label', value)}
                                                                onBlur={() => formik.setFieldTouched('form_button_label')}
                                                                error={formik.errors.form_button_label && formik.touched.form_button_label ? formik.errors.form_button_label : null}
                                                                autoComplete="off"
                                                            />
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                            <TextField
                                                                type="text"
                                                                label="Footer Text"
                                                                name='form_footer_text'
                                                                value={formik.values.form_footer_text}
                                                                onChange={(value) => formik.setFieldValue('form_footer_text', value)}
                                                                onBlur={() => formik.setFieldTouched('form_footer_text')}
                                                                error={formik.errors.form_footer_text && formik.touched.form_footer_text ? formik.errors.form_footer_text : null}
                                                                autoComplete="off"
                                                            />
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                            <TextField
                                                                type="text"
                                                                label="Close Button Tooltip"
                                                                name='close_button_tooltip'
                                                                value={formik.values.close_button_tooltip}
                                                                onChange={(value) => { formik.setFieldValue('close_button_tooltip', value) }}
                                                                onBlur={() => formik.setFieldTouched('close_button_tooltip')}
                                                                error={formik.errors.close_button_tooltip && formik.touched.close_button_tooltip ? formik.errors.close_button_tooltip : null}
                                                                autoComplete="off"
                                                            />
                                                        </Grid.Cell>
                                                        {/* <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                    <TextField
                                                        type="text"
                                                        label="Push Notification Label"
                                                        value={formik.values.push_notification_label}
                                                        onChange={(value) => formik.setFieldValue('push_notification_label', value)}
                                                        onBlur={() => formik.setFieldTouched('push_notification_label')}
                                                        error={formik.errors.push_notification_label && formik.touched.push_notification_label ? formik.errors.push_notification_label : null}
                                                        autoComplete="off"
                                                    />
                                                </Grid.Cell> */}
                                                    </Grid>}
                                                    {((selecte === 1 && formik.values.custom_style === 0) || (selected === 4 && formik.values.custom_style === 1)) && <FormLayout>
                                                        <TextField
                                                            label="Registration Complete"
                                                            value={formik.values.registration_complete}
                                                            onChange={(value) => formik.setFieldValue('registration_complete', value)}
                                                            onBlur={() => formik.setFieldTouched('registration_complete')}
                                                            name='registration_complete'
                                                            error={formik.errors.registration_complete && formik.touched.registration_complete ? formik.errors.registration_complete : null}
                                                            autoComplete="off"
                                                        />
                                                        <TextField
                                                            type="email"
                                                            label="Email Address is Invalid"
                                                            value={formik.values.invalid_email_message}
                                                            onChange={(value) => formik.setFieldValue('invalid_email_message', value)}
                                                            onBlur={() => formik.setFieldTouched('invalid_email_message')}
                                                            name='invalid_email_message'
                                                            error={formik.errors.invalid_email_message && formik.touched.invalid_email_message ? formik.errors.invalid_email_message : null}
                                                            autoComplete="email"
                                                        />
                                                        <TextField
                                                            type="email"
                                                            label="Please Provide email address"
                                                            name='provide_email_phone'
                                                            value={formik.values.provide_email_phone}
                                                            onChange={(value) => formik.setFieldValue('provide_email_phone', value)}
                                                            onBlur={() => formik.setFieldTouched('provide_email_phone')}
                                                            error={formik.errors.provide_email_phone && formik.touched.provide_email_phone ? formik.errors.provide_email_phone : null}
                                                            autoComplete="email"
                                                        />
                                                    </FormLayout>}
                                                    {((selecte === 2 && formik.values.custom_style === 0) || (selected === 5 && formik.values.custom_style === 1)) && <div className='signup-settings color-settings'>
                                                        <Grid columns={{ sm: 3 }}>
                                                            <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                                <PolarisColorInput
                                                                    handlechange={(value) => formik.setFieldValue('form_bg_color', value)}
                                                                    value={formik.values.form_bg_color}
                                                                    label={'Background Color'}
                                                                    name={'form_bg_color'}
                                                                />
                                                            </Grid.Cell>
                                                            <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                                <Select
                                                                    label="Font"
                                                                    placeholder='select'
                                                                    name='form_font_family'
                                                                    options={fontFamilyOptions}
                                                                    onChange={(value) => formik.setFieldValue('form_font_family', value)}
                                                                    value={formik.values.form_font_family}
                                                                />
                                                            </Grid.Cell>
                                                            <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                                <PolarisColorInput
                                                                    handlechange={(value) => formik.setFieldValue('form_border_color', value)}
                                                                    value={formik.values.form_border_color}
                                                                    label={'Border Color'}
                                                                    name={'form_border_color'}
                                                                />
                                                            </Grid.Cell>
                                                            <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                                <TextField
                                                                    type="number"
                                                                    value={formik.values.form_border_width}
                                                                    label="Border Width"
                                                                    name='form_border_width'
                                                                    onChange={(value) => { formik.setFieldValue('form_border_width', value); }}
                                                                    autoComplete="10"
                                                                />
                                                            </Grid.Cell>
                                                            <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                                <PolarisColorInput
                                                                    handlechange={(value) => formik.setFieldValue('form_text_color', value)}
                                                                    value={formik.values.form_text_color}
                                                                    label={'Text Color'}
                                                                    name={'form_text_color'}
                                                                />
                                                            </Grid.Cell>
                                                            <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                                <PolarisColorInput
                                                                    handlechange={(value) => formik.setFieldValue('form_close_button_color', value)}
                                                                    value={formik.values.form_close_button_color}
                                                                    label={'Close Button Color'}
                                                                    name={'form_close_button_color'}
                                                                />
                                                            </Grid.Cell>
                                                            <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                                <PolarisColorInput
                                                                    handlechange={(value) => formik.setFieldValue('form_overlay_tint_color', value)}
                                                                    value={formik.values.form_overlay_tint_color}
                                                                    label={'Overlay Tint Color'}
                                                                    name={'form_overlay_tint_color'}
                                                                />
                                                            </Grid.Cell>
                                                        </Grid>
                                                        <LegacyCard.Section title="BUTTON">
                                                            <Grid columns={{ sm: 3 }}>
                                                                <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                                    <PolarisColorInput
                                                                        handlechange={(value) => formik.setFieldValue('form_button_bg_color', value)}
                                                                        value={formik.values.form_button_bg_color}
                                                                        label={'Background Color'}
                                                                        name={'form_button_bg_color'}
                                                                    />
                                                                </Grid.Cell>
                                                                <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                                    <PolarisColorInput
                                                                        handlechange={(value) => formik.setFieldValue('form_button_text_color', value)}
                                                                        value={formik.values.form_button_text_color}
                                                                        label={'Text Color'}
                                                                        name={'form_button_text_color'}
                                                                    />
                                                                </Grid.Cell>
                                                                <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                                    <TextField
                                                                        type='number'
                                                                        label={'Border Width'}
                                                                        suffix='px'
                                                                        min={0}
                                                                        name={'form_button_border_width'}
                                                                        value={formik.values.form_button_border_width}
                                                                        onChange={(value) => formik.setFieldValue('form_button_border_width', value)}
                                                                        autoComplete="off"
                                                                    />
                                                                </Grid.Cell>
                                                            </Grid>
                                                        </LegacyCard.Section>
                                                        <LegacyCard.Section title="SUCCESS MESSAGE">
                                                            <Grid columns={{ sm: 3 }}>
                                                                <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                                    <PolarisColorInput
                                                                        handlechange={(value) => formik.setFieldValue('form_success_bg_color', value)}
                                                                        value={formik.values.form_success_bg_color}
                                                                        label={'Background Color'}
                                                                        name={'form_success_bg_color'}
                                                                    />
                                                                </Grid.Cell>
                                                                <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                                    <PolarisColorInput
                                                                        handlechange={(value) => formik.setFieldValue('form_success_text_color', value)}
                                                                        value={formik.values.form_success_text_color}
                                                                        label={'Text Color'}
                                                                        name={'form_success_text_color'}
                                                                    />
                                                                </Grid.Cell>
                                                            </Grid>
                                                        </LegacyCard.Section>
                                                        <LegacyCard.Section title="ERROR MESSAGE">
                                                            <Grid columns={{ sm: 3 }}>
                                                                <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                                    <PolarisColorInput
                                                                        handlechange={(value) => formik.setFieldValue('form_error_bg_color', value)}
                                                                        value={formik.values.form_error_bg_color}
                                                                        label={'Background Color'}
                                                                        name={'form_error_bg_color'}
                                                                    />
                                                                </Grid.Cell>
                                                                <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                                    <PolarisColorInput
                                                                        handlechange={(value) => formik.setFieldValue('form_error_text_color', value)}
                                                                        value={formik.values.form_error_text_color}
                                                                        label={'Text Color'}
                                                                        name={'form_error_text_color'}
                                                                    />
                                                                </Grid.Cell>
                                                            </Grid>
                                                        </LegacyCard.Section>
                                                        <LegacyCard.Section title="INPUT FIELD (EMAIL)">
                                                            <Grid columns={{ sm: 3 }}>
                                                                <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                                    <PolarisColorInput
                                                                        handlechange={(value) => formik.setFieldValue('form_input_border_color', value)}
                                                                        value={formik.values.form_input_border_color}
                                                                        label={'Border Color'}
                                                                        name={'form_input_border_color'}
                                                                    />
                                                                </Grid.Cell>
                                                                <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                                    <TextField
                                                                        type='number'
                                                                        label={'Border Width'}
                                                                        min={0}
                                                                        suffix='px'
                                                                        name={'form_input_border_width'}
                                                                        value={formik.values.form_input_border_width}
                                                                        onChange={(value) => formik.setFieldValue('form_input_border_width', value)}
                                                                        autoComplete="off"
                                                                    />
                                                                </Grid.Cell>
                                                                <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                                    <TextField
                                                                        type='number'
                                                                        label={'Border Radius'}
                                                                        suffix='px'
                                                                        min={0}
                                                                        name={'form_input_border_radius'}
                                                                        value={formik.values.form_input_border_radius}
                                                                        onChange={(value) => formik.setFieldValue('form_input_border_radius', value)}
                                                                        autoComplete="off"
                                                                    />
                                                                </Grid.Cell>
                                                            </Grid>
                                                        </LegacyCard.Section>
                                                    </div>}
                                                </LegacyCard.Section>
                                            </Tabs>
                                        </LegacyCard>
                                        <LegacyCard title='Product available style'>
                                            <Divider />
                                            <LegacyCard.Section>
                                                <Grid columns={{ sm: 3 }}>
                                                    <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                        <div className='product_radio'>
                                                            <RadioButton
                                                                label="Radio list"
                                                                onChange={() => { formik.setFieldValue('product_ava_style', 0); }}
                                                                id='product_ava_style_0'
                                                                name="product_ava_style_0"
                                                                checked={formik.values.product_ava_style === 0}
                                                            />
                                                            <RadioButton
                                                                label="Select box"
                                                                id="product_ava_style_1"
                                                                checked={formik.values.product_ava_style === 1}
                                                                name="product_ava_style_1"
                                                                onChange={() => { formik.setFieldValue('product_ava_style', 1); }}
                                                            />
                                                        </div>
                                                    </Grid.Cell>
                                                </Grid>
                                            </LegacyCard.Section>
                                        </LegacyCard>
                                        <PageActions
                                            primaryAction={{
                                                content: 'Save',
                                                onAction: () => formik.handleSubmit(),
                                                loading: loading
                                            }}
                                        />
                                    </div>
                                </Layout.Section>
                                <Layout.Section secondary >
                                    <div className='product_lheader'>
                                        <LegacyCard title='See preview how it show in product detail page'>
                                            <Divider />
                                            <div className='product_card'>
                                                <LegacyCard.Section>
                                                    <FormLayout>
                                                        <FormLayout.Group>
                                                            <img width="100%" src={PreviewImg} />
                                                            <div>
                                                                <Text variant="heading3xl" as="h2">Striped Silk Blouse</Text>
                                                                <Text variant="headingMd" as="h6">$50.00</Text>
                                                                <div className='preview-divider'>
                                                                    <Divider borderColor="border-inverse" />
                                                                </div>
                                                                <span>Size</span>
                                                                <div className='product_btn'>
                                                                    <Button>SMALL</Button>
                                                                    <Button>
                                                                        <Text as="p" textDecorationLine="line-through">MEDIUM</Text>
                                                                    </Button>
                                                                    <Button>
                                                                        <Text as="p" textDecorationLine="line-through">LARGE</Text>
                                                                    </Button>
                                                                </div>
                                                                <ButtonGroup>
                                                                    <Button disabled>SOLD OUT</Button>
                                                                    <Button disabled>BUY IT NOW</Button>
                                                                </ButtonGroup>
                                                                <style>{preview_btn}</style>
                                                                <div className='notify-btn-preview'>
                                                                    <Button fullWidth>{formik.values.modal_button_caption}</Button>
                                                                </div>
                                                            </div>
                                                        </FormLayout.Group>
                                                    </FormLayout>
                                                </LegacyCard.Section>
                                            </div>
                                        </LegacyCard>
                                    </div>
                                    <div className='action_form'>
                                        <LegacyCard>
                                            <LegacyCard.Section>
                                                <FormLayout>
                                                    <Text variant="headingLg" as="h5">{formik.values.form_header_text}</Text>
                                                    <Text as="p" tone="subdued">
                                                        {formik.values.form_body_text}
                                                    </Text>
                                                    <div className='email-preview'>
                                                        <TextField value={contactContent} type="text" placeholder={formik.values.form_email_address_label} onChange={(value) => { setContactContent(value); }} autoComplete="off" />
                                                    </div>
                                                    <div className='preview-btn'>
                                                        <Button fullWidth>{formik.values.form_button_label}</Button>
                                                    </div>
                                                    <Text as="p" tone="subdued">
                                                        {formik.values.form_footer_text}
                                                    </Text>
                                                    <Divider />
                                                    <Text as="p" tone="subdued" alignment='center'>Powered by Back in Stock</Text>
                                                </FormLayout>
                                            </LegacyCard.Section>
                                        </LegacyCard>
                                    </div>
                                </Layout.Section>
                                {toastMarkup}
                            </Layout>
                            <Footer />
                        </Page>
                    </AppBridgeProvider>
                </AppProvider>
            </> : <>
                <Page fullWidth backAction={{ content: 'Products', onAction: () => navigate('/setting') }} title={'Product page setting'}>
                    <Layout>
                        <Layout.Section>
                            <div className='product_lheader'>
                                <LegacyCard title='Select your custom style'>
                                    <Divider />
                                    <LegacyCard.Section>
                                        <div className='product_radio'>
                                            <RadioButton
                                                label="In-line Form"
                                                onChange={() => { formik.setFieldValue('custom_style', 0); }}
                                                id='custom_style_0'
                                                name="custom_style_0"
                                                checked={formik.values.custom_style === 0}

                                            />
                                            <RadioButton
                                                label="Modal / Pop-up"
                                                id="custom_style_1"
                                                checked={formik.values.custom_style === 1}
                                                name="custom_style_1"
                                                onChange={() => { formik.setFieldValue('custom_style', 1); }}
                                            />
                                        </div>
                                    </LegacyCard.Section>
                                </LegacyCard>
                                <LegacyCard title='Personalize your design'>
                                    <Divider />
                                    <Tabs
                                        tabs={formik.values.custom_style === 1 ? tab : tabs}
                                        selected={formik.values.custom_style === 1 ? selected : selecte}
                                        onSelect={formik.values.custom_style === 1 ? handleTabChange : handleTabChanged}
                                        disclosureText="More views"
                                    >
                                        <Divider />
                                        <LegacyCard.Section>
                                            {(selected === 0 && formik.values.custom_style === 1) && <Grid columns={{ sm: 3 }}>
                                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                    <TextField
                                                        type="text"
                                                        label="Button caption"
                                                        name='modal_button_caption'
                                                        value={formik.values.modal_button_caption}
                                                        onChange={(value) => { formik.setFieldValue('modal_button_caption', value); }}
                                                        onBlur={() => formik.setFieldTouched('modal_button_caption')}
                                                        error={formik.errors.modal_button_caption && formik.touched.modal_button_caption ? formik.errors.modal_button_caption : null}
                                                        autoComplete="off"
                                                    />
                                                </Grid.Cell>
                                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                    <TextField
                                                        type="text"
                                                        label="Theme CSS classes (space or comma separated)"
                                                        name='modal_theme_css_classes'
                                                        value={formik.values.modal_theme_css_classes}
                                                        onChange={(value) => { formik.setFieldValue('modal_theme_css_classes', value); }}
                                                        autoComplete="off"
                                                    />
                                                </Grid.Cell>
                                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                    <TextField
                                                        type="number"
                                                        label="Text size"
                                                        min={0}
                                                        name='modal_text_size'
                                                        suffix='px'
                                                        value={formik.values.modal_text_size}
                                                        onChange={(value) => { formik.setFieldValue('modal_text_size', value); }}
                                                        autoComplete="off"
                                                    />
                                                </Grid.Cell>
                                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                    <Select
                                                        label="Text style"
                                                        placeholder='select'
                                                        name='modal_text_style'
                                                        options={textStyleOptions}
                                                        onChange={(value) => { formik.setFieldValue('modal_text_style', value); }}
                                                        value={formik.values.modal_text_style}
                                                    />
                                                </Grid.Cell>
                                            </Grid>}
                                            {(selected === 1 && formik.values.custom_style === 1) && <Grid columns={{ sm: 3 }}>
                                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                    <TextField
                                                        type="number"
                                                        label="Button Width"
                                                        min={0}
                                                        suffix='%'
                                                        name='modal_btn_width'
                                                        value={formik.values.modal_btn_width}
                                                        onChange={(value) => { formik.setFieldValue('modal_btn_width', value); }}
                                                        autoComplete="off"
                                                    />
                                                </Grid.Cell>
                                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                    <TextField
                                                        type="number"
                                                        label="Button height"
                                                        min={0}
                                                        suffix='px'
                                                        name='modal_button_height'
                                                        value={formik.values.modal_button_height}
                                                        onChange={(value) => { formik.setFieldValue('modal_button_height', value); }}
                                                        autoComplete="off"
                                                    />
                                                </Grid.Cell>
                                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                    <TextField
                                                        type="number"
                                                        label="Margin top"
                                                        suffix='px'
                                                        name='modal_margin_top'
                                                        value={formik.values.modal_margin_top}
                                                        onChange={(value) => { formik.setFieldValue('modal_margin_top', value); }}
                                                        autoComplete="off"
                                                    />
                                                </Grid.Cell>
                                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                    <TextField
                                                        type="number"
                                                        label="Margin bottom"
                                                        suffix='px'
                                                        name='modal_margin_bottom'
                                                        value={formik.values.modal_margin_bottom}
                                                        onChange={(value) => { formik.setFieldValue('modal_margin_bottom', value); }}
                                                        autoComplete="off"
                                                    />
                                                </Grid.Cell>
                                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                    <TextField
                                                        type="number"
                                                        label="Margin left"
                                                        suffix='px'
                                                        name='modal_margin_left'
                                                        value={formik.values.modal_margin_left}
                                                        onChange={(value) => { formik.setFieldValue('modal_margin_left', value); }}
                                                        autoComplete="off"
                                                    />
                                                </Grid.Cell>
                                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                    <TextField
                                                        type="number"
                                                        label="Margin right"
                                                        suffix='px'
                                                        name='modal_margin_right'
                                                        value={formik.values.modal_margin_right}
                                                        onChange={(value) => { formik.setFieldValue('modal_margin_right', value); }}
                                                        autoComplete="off"
                                                    />
                                                </Grid.Cell>
                                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                    <TextField
                                                        type="number"
                                                        label="Border size"
                                                        min={0}
                                                        suffix='px'
                                                        name='modal_border_size'
                                                        value={formik.values.modal_border_size}
                                                        onChange={(value) => { formik.setFieldValue('modal_border_size', value); }}
                                                        autoComplete="off"
                                                    />
                                                </Grid.Cell>
                                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                    <TextField
                                                        type="number"
                                                        label="Border radius"
                                                        min={0}
                                                        suffix='px'
                                                        name='modal_border_radius'
                                                        value={formik.values.modal_border_radius}
                                                        onChange={(value) => { formik.setFieldValue('modal_border_radius', value); }}
                                                        autoComplete="off"
                                                    />
                                                </Grid.Cell>
                                            </Grid>}
                                            {(selected === 2 && formik.values.custom_style === 1) && <div className='color-settings'>
                                                <Grid columns={{ sm: 3 }}>
                                                    <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                        <PolarisColorInput
                                                            handlechange={(value) => formik.setFieldValue('btn_bg_color', value)}
                                                            value={formik.values.btn_bg_color}
                                                            label={'Background Color'}
                                                            name={'btn_bg_color'}
                                                        />
                                                    </Grid.Cell>
                                                    <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                        <PolarisColorInput
                                                            handlechange={(value) => formik.setFieldValue('btn_hover_bg_color', value)}
                                                            value={formik.values.btn_hover_bg_color}
                                                            label={'Hover Background Color'}
                                                            name={'btn_hover_bg_color'}
                                                        />
                                                    </Grid.Cell>
                                                    <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                        <PolarisColorInput
                                                            handlechange={(value) => formik.setFieldValue('btn_text_color', value)}
                                                            value={formik.values.btn_text_color}
                                                            label={'Text Color'}
                                                            name={'btn_text_color'}
                                                        />
                                                    </Grid.Cell>
                                                    <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                        <PolarisColorInput
                                                            handlechange={(value) => formik.setFieldValue('btn_hover_text_color', value)}
                                                            value={formik.values.btn_hover_text_color}
                                                            label={'Hover Text Color'}
                                                            name={'btn_hover_text_color'}
                                                        />
                                                    </Grid.Cell>
                                                    <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                        <PolarisColorInput
                                                            handlechange={(value) => formik.setFieldValue('btn_border_color', value)}
                                                            value={formik.values.btn_border_color}
                                                            label={'Border Color'}
                                                            name={'btn_border_color'}
                                                        />
                                                    </Grid.Cell>
                                                    <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                        <PolarisColorInput
                                                            handlechange={(value) => formik.setFieldValue('btn_hover_border_color', value)}
                                                            value={formik.values.btn_hover_border_color}
                                                            label={'Hover Border Color'}
                                                            name={'btn_hover_border_color'}
                                                        />
                                                    </Grid.Cell>
                                                </Grid>
                                            </div>}
                                            {(((selecte === 0) && (formik.values.custom_style === 0)) || (selected === 3 && formik.values.custom_style === 1)) && <Grid columns={{ sm: 3 }}>
                                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                    <TextField
                                                        type="text"
                                                        label="Header Text"
                                                        name='form_header_text'
                                                        value={formik.values.form_header_text}
                                                        onChange={(value) => formik.setFieldValue('form_header_text', value)}
                                                        onBlur={() => formik.setFieldTouched('form_header_text')}
                                                        error={formik.errors.form_header_text && formik.touched.form_header_text ? formik.errors.form_header_text : null}
                                                        autoComplete="off"
                                                    />
                                                </Grid.Cell>
                                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                    <TextField
                                                        type="text"
                                                        label="Body Text"
                                                        multiline={2}
                                                        name='form_body_text'
                                                        value={formik.values.form_body_text}
                                                        onChange={(value) => formik.setFieldValue('form_body_text', value)}
                                                        onBlur={() => formik.setFieldTouched('form_body_text')}
                                                        error={formik.errors.form_body_text && formik.touched.form_body_text ? formik.errors.form_body_text : null}
                                                        autoComplete="off"
                                                    />
                                                </Grid.Cell>
                                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                    <TextField
                                                        type="text"
                                                        label="Email Addresss Label"
                                                        name='form_email_address_label'
                                                        value={formik.values.form_email_address_label}
                                                        onChange={(value) => formik.setFieldValue('form_email_address_label', value)}
                                                        onBlur={() => formik.setFieldTouched('form_email_address_label')}
                                                        error={formik.errors.form_email_address_label && formik.touched.form_email_address_label ? formik.errors.form_email_address_label : null}
                                                        autoComplete="off"
                                                    />
                                                </Grid.Cell>
                                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                    <TextField
                                                        type="text"
                                                        label="Button Label"
                                                        name='form_button_label'
                                                        value={formik.values.form_button_label}
                                                        onChange={(value) => formik.setFieldValue('form_button_label', value)}
                                                        onBlur={() => formik.setFieldTouched('form_button_label')}
                                                        error={formik.errors.form_button_label && formik.touched.form_button_label ? formik.errors.form_button_label : null}
                                                        autoComplete="off"
                                                    />
                                                </Grid.Cell>
                                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                    <TextField
                                                        type="text"
                                                        label="Footer Text"
                                                        name='form_footer_text'
                                                        value={formik.values.form_footer_text}
                                                        onChange={(value) => formik.setFieldValue('form_footer_text', value)}
                                                        onBlur={() => formik.setFieldTouched('form_footer_text')}
                                                        error={formik.errors.form_footer_text && formik.touched.form_footer_text ? formik.errors.form_footer_text : null}
                                                        autoComplete="off"
                                                    />
                                                </Grid.Cell>
                                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                    <TextField
                                                        type="text"
                                                        label="Close Button Tooltip"
                                                        name='close_button_tooltip'
                                                        value={formik.values.close_button_tooltip}
                                                        onChange={(value) => { formik.setFieldValue('close_button_tooltip', value) }}
                                                        onBlur={() => formik.setFieldTouched('close_button_tooltip')}
                                                        error={formik.errors.close_button_tooltip && formik.touched.close_button_tooltip ? formik.errors.close_button_tooltip : null}
                                                        autoComplete="off"
                                                    />
                                                </Grid.Cell>
                                                {/* <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                    <TextField
                                                        type="text"
                                                        label="Push Notification Label"
                                                        value={formik.values.push_notification_label}
                                                        onChange={(value) => formik.setFieldValue('push_notification_label', value)}
                                                        onBlur={() => formik.setFieldTouched('push_notification_label')}
                                                        error={formik.errors.push_notification_label && formik.touched.push_notification_label ? formik.errors.push_notification_label : null}
                                                        autoComplete="off"
                                                    />
                                                </Grid.Cell> */}
                                            </Grid>}
                                            {((selecte === 1 && formik.values.custom_style === 0) || (selected === 4 && formik.values.custom_style === 1)) && <FormLayout>
                                                <TextField
                                                    label="Registration Complete"
                                                    value={formik.values.registration_complete}
                                                    onChange={(value) => formik.setFieldValue('registration_complete', value)}
                                                    onBlur={() => formik.setFieldTouched('registration_complete')}
                                                    name='registration_complete'
                                                    error={formik.errors.registration_complete && formik.touched.registration_complete ? formik.errors.registration_complete : null}
                                                    autoComplete="off"
                                                />
                                                <TextField
                                                    type="email"
                                                    label="Email Address is Invalid"
                                                    value={formik.values.invalid_email_message}
                                                    onChange={(value) => formik.setFieldValue('invalid_email_message', value)}
                                                    onBlur={() => formik.setFieldTouched('invalid_email_message')}
                                                    name='invalid_email_message'
                                                    error={formik.errors.invalid_email_message && formik.touched.invalid_email_message ? formik.errors.invalid_email_message : null}
                                                    autoComplete="email"
                                                />
                                                <TextField
                                                    type="email"
                                                    label="Please Provide email address"
                                                    name='provide_email_phone'
                                                    value={formik.values.provide_email_phone}
                                                    onChange={(value) => formik.setFieldValue('provide_email_phone', value)}
                                                    onBlur={() => formik.setFieldTouched('provide_email_phone')}
                                                    error={formik.errors.provide_email_phone && formik.touched.provide_email_phone ? formik.errors.provide_email_phone : null}
                                                    autoComplete="email"
                                                />
                                            </FormLayout>}
                                            {((selecte === 2 && formik.values.custom_style === 0) || (selected === 5 && formik.values.custom_style === 1)) && <div className='signup-settings color-settings'>
                                                <Grid columns={{ sm: 3 }}>
                                                    <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                        <PolarisColorInput
                                                            handlechange={(value) => formik.setFieldValue('form_bg_color', value)}
                                                            value={formik.values.form_bg_color}
                                                            label={'Background Color'}
                                                            name={'form_bg_color'}
                                                        />
                                                    </Grid.Cell>
                                                    <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                        <Select
                                                            label="Font"
                                                            placeholder='select'
                                                            name='form_font_family'
                                                            options={fontFamilyOptions}
                                                            onChange={(value) => formik.setFieldValue('form_font_family', value)}
                                                            value={formik.values.form_font_family}
                                                        />
                                                    </Grid.Cell>
                                                    <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                        <PolarisColorInput
                                                            handlechange={(value) => formik.setFieldValue('form_border_color', value)}
                                                            value={formik.values.form_border_color}
                                                            label={'Border Color'}
                                                            name={'form_border_color'}
                                                        />
                                                    </Grid.Cell>
                                                    <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                        <TextField
                                                            type="number"
                                                            value={formik.values.form_border_width}
                                                            label="Border Width"
                                                            name='form_border_width'
                                                            onChange={(value) => { formik.setFieldValue('form_border_width', value); }}
                                                            autoComplete="10"
                                                        />
                                                    </Grid.Cell>
                                                    <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                        <PolarisColorInput
                                                            handlechange={(value) => formik.setFieldValue('form_text_color', value)}
                                                            value={formik.values.form_text_color}
                                                            label={'Text Color'}
                                                            name={'form_text_color'}
                                                        />
                                                    </Grid.Cell>
                                                    <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                        <PolarisColorInput
                                                            handlechange={(value) => formik.setFieldValue('form_close_button_color', value)}
                                                            value={formik.values.form_close_button_color}
                                                            label={'Close Button Color'}
                                                            name={'form_close_button_color'}
                                                        />
                                                    </Grid.Cell>
                                                    <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                        <PolarisColorInput
                                                            handlechange={(value) => formik.setFieldValue('form_overlay_tint_color', value)}
                                                            value={formik.values.form_overlay_tint_color}
                                                            label={'Overlay Tint Color'}
                                                            name={'form_overlay_tint_color'}
                                                        />
                                                    </Grid.Cell>
                                                </Grid>
                                                <LegacyCard.Section title="BUTTON">
                                                    <Grid columns={{ sm: 3 }}>
                                                        <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                            <PolarisColorInput
                                                                handlechange={(value) => formik.setFieldValue('form_button_bg_color', value)}
                                                                value={formik.values.form_button_bg_color}
                                                                label={'Background Color'}
                                                                name={'form_button_bg_color'}
                                                            />
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                            <PolarisColorInput
                                                                handlechange={(value) => formik.setFieldValue('form_button_text_color', value)}
                                                                value={formik.values.form_button_text_color}
                                                                label={'Text Color'}
                                                                name={'form_button_text_color'}
                                                            />
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                            <TextField
                                                                type='number'
                                                                label={'Border Width'}
                                                                suffix='px'
                                                                min={0}
                                                                name={'form_button_border_width'}
                                                                value={formik.values.form_button_border_width}
                                                                onChange={(value) => formik.setFieldValue('form_button_border_width', value)}
                                                                autoComplete="off"
                                                            />
                                                        </Grid.Cell>
                                                    </Grid>
                                                </LegacyCard.Section>
                                                <LegacyCard.Section title="SUCCESS MESSAGE">
                                                    <Grid columns={{ sm: 3 }}>
                                                        <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                            <PolarisColorInput
                                                                handlechange={(value) => formik.setFieldValue('form_success_bg_color', value)}
                                                                value={formik.values.form_success_bg_color}
                                                                label={'Background Color'}
                                                                name={'form_success_bg_color'}
                                                            />
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                            <PolarisColorInput
                                                                handlechange={(value) => formik.setFieldValue('form_success_text_color', value)}
                                                                value={formik.values.form_success_text_color}
                                                                label={'Text Color'}
                                                                name={'form_success_text_color'}
                                                            />
                                                        </Grid.Cell>
                                                    </Grid>
                                                </LegacyCard.Section>
                                                <LegacyCard.Section title="ERROR MESSAGE">
                                                    <Grid columns={{ sm: 3 }}>
                                                        <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                            <PolarisColorInput
                                                                handlechange={(value) => formik.setFieldValue('form_error_bg_color', value)}
                                                                value={formik.values.form_error_bg_color}
                                                                label={'Background Color'}
                                                                name={'form_error_bg_color'}
                                                            />
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                            <PolarisColorInput
                                                                handlechange={(value) => formik.setFieldValue('form_error_text_color', value)}
                                                                value={formik.values.form_error_text_color}
                                                                label={'Text Color'}
                                                                name={'form_error_text_color'}
                                                            />
                                                        </Grid.Cell>
                                                    </Grid>
                                                </LegacyCard.Section>
                                                <LegacyCard.Section title="INPUT FIELD (EMAIL)">
                                                    <Grid columns={{ sm: 3 }}>
                                                        <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                            <PolarisColorInput
                                                                handlechange={(value) => formik.setFieldValue('form_input_border_color', value)}
                                                                value={formik.values.form_input_border_color}
                                                                label={'Border Color'}
                                                                name={'form_input_border_color'}
                                                            />
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                            <TextField
                                                                type='number'
                                                                label={'Border Width'}
                                                                min={0}
                                                                suffix='px'
                                                                name={'form_input_border_width'}
                                                                value={formik.values.form_input_border_width}
                                                                onChange={(value) => formik.setFieldValue('form_input_border_width', value)}
                                                                autoComplete="off"
                                                            />
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{ xs: 4, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                            <TextField
                                                                type='number'
                                                                label={'Border Radius'}
                                                                suffix='px'
                                                                min={0}
                                                                name={'form_input_border_radius'}
                                                                value={formik.values.form_input_border_radius}
                                                                onChange={(value) => formik.setFieldValue('form_input_border_radius', value)}
                                                                autoComplete="off"
                                                            />
                                                        </Grid.Cell>
                                                    </Grid>
                                                </LegacyCard.Section>
                                            </div>}
                                        </LegacyCard.Section>
                                    </Tabs>
                                </LegacyCard>
                                <LegacyCard title='Product available style'>
                                    <Divider />
                                    <LegacyCard.Section>
                                        <Grid columns={{ sm: 3 }}>
                                            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                                <div className='product_radio'>
                                                    <RadioButton
                                                        label="Radio list"
                                                        onChange={() => { formik.setFieldValue('product_ava_style', 0); }}
                                                        id='product_ava_style_0'
                                                        name="product_ava_style_0"
                                                        checked={formik.values.product_ava_style === 0}
                                                    />
                                                    <RadioButton
                                                        label="Select box"
                                                        id="product_ava_style_1"
                                                        checked={formik.values.product_ava_style === 1}
                                                        name="product_ava_style_1"
                                                        onChange={() => { formik.setFieldValue('product_ava_style', 1); }}
                                                    />
                                                </div>
                                            </Grid.Cell>
                                        </Grid>
                                    </LegacyCard.Section>
                                </LegacyCard>
                                <PageActions
                                    primaryAction={{
                                        content: 'Save',
                                        onAction: () => formik.handleSubmit(),
                                        loading: loading
                                    }}
                                />
                            </div>
                        </Layout.Section>
                        <Layout.Section secondary >
                            <div className='product_lheader'>
                                <LegacyCard title='See preview how it show in product detail page'>
                                    <Divider />
                                    <div className='product_card'>
                                        <LegacyCard.Section>
                                            <FormLayout>
                                                <FormLayout.Group>
                                                    <img width="100%" src={PreviewImg} />
                                                    <div>
                                                        <Text variant="heading3xl" as="h2">Striped Silk Blouse</Text>
                                                        <Text variant="headingMd" as="h6">$50.00</Text>
                                                        <div className='preview-divider'>
                                                            <Divider borderColor="border-inverse" />
                                                        </div>
                                                        <span>Size</span>
                                                        <div className='product_btn'>
                                                            <Button>SMALL</Button>
                                                            <Button>
                                                                <Text as="p" textDecorationLine="line-through">MEDIUM</Text>
                                                            </Button>
                                                            <Button>
                                                                <Text as="p" textDecorationLine="line-through">LARGE</Text>
                                                            </Button>
                                                        </div>
                                                        <ButtonGroup>
                                                            <Button disabled>SOLD OUT</Button>
                                                            <Button disabled>BUY IT NOW</Button>
                                                        </ButtonGroup>
                                                        <style>{preview_btn}</style>
                                                        <div className='notify-btn-preview'>
                                                            <Button fullWidth>{formik.values.modal_button_caption}</Button>
                                                        </div>
                                                    </div>
                                                </FormLayout.Group>
                                            </FormLayout>
                                        </LegacyCard.Section>
                                    </div>
                                </LegacyCard>
                            </div>
                            <div className='action_form'>
                                <LegacyCard>
                                    <LegacyCard.Section>
                                        <FormLayout>
                                            <Text variant="headingLg" as="h5">{formik.values.form_header_text}</Text>
                                            <Text as="p" tone="subdued">
                                                {formik.values.form_body_text}
                                            </Text>
                                            <div className='email-preview'>
                                                <TextField value={contactContent} type="text" placeholder={formik.values.form_email_address_label} onChange={(value) => { setContactContent(value); }} autoComplete="off" />
                                            </div>
                                            <div className='preview-btn'>
                                                <Button fullWidth>{formik.values.form_button_label}</Button>
                                            </div>
                                            <Text as="p" tone="subdued">
                                                {formik.values.form_footer_text}
                                            </Text>
                                            <Divider />
                                            <Text as="p" tone="subdued" alignment='center'>Powered by Back in Stock</Text>
                                        </FormLayout>
                                    </LegacyCard.Section>
                                </LegacyCard>
                            </div>
                        </Layout.Section>
                        {toastMarkup}
                    </Layout>
                    <Footer />
                </Page>
            </>}
        </>
    )
}

export default SettingProduct;