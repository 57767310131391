import { FooterHelp, Link } from "@shopify/polaris";
import React from "react";

const Footer = () => {
  return (
    <FooterHelp>
      If you need any help, please {" "} 
      <Link url="mailto:info@backinstocktech.com" target="_blank">
      {" "}Contact us
      </Link>
    </FooterHelp>
  );
};
export default Footer;