import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { GetApiCall, ApiCall } from '../helper/axios';
import {
    IndexFilters, IndexTable, LegacyCard, Page, TextField, useIndexResourceState, useSetIndexFiltersMode, Badge,
    HorizontalStack, Icon, OptionList, Popover, Scrollable, Select, VerticalStack, useBreakpoints,
    Box, Button, DatePicker, HorizontalGrid, Pagination, Toast, AppProvider
} from '@shopify/polaris';
import {
    Provider as AppBridgeProvider,
    TitleBar,
} from "@shopify/app-bridge-react";
import { config } from "../helper/commonApi";
import { ArrowRightMinor, CalendarMinor } from '@shopify/polaris-icons';
import Footer from './Footer';

const Customer = () => {
    const navigate = useNavigate();
    const clientStoreData = useSelector(state => state.clientStoreData);
    const storeInfo = clientStoreData.clientInfo?.storeInfo;
    const header = { authentication: storeInfo?.token };
    const [emailVal, setEmailVal] = useState('');
    const [selected, setSelected] = useState(0);
    const itemStrings = ['All', 'Unsent', 'Sent'];
    const [protitleValue, setProtitleValue] = useState('');
    const [currpage, setCurrPage] = useState(1);
    const [totalpage, settotalPage] = useState(1);
    const [toastMsg, setToastMsg] = useState('');
    const [activeEnable, setActiveEnable] = useState(false);
    const [filterDate, setFilterDate] = useState([]);
    const appliedFilters = [];
    const [customer, setCustomer] = useState([]);
    const { mdDown, lgUp } = useBreakpoints();
    const shouldShowMultiMonth = lgUp;
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const yesterday = new Date(
        new Date(new Date().setDate(today.getDate() - 1)).setHours(0, 0, 0, 0)
    );
    const ranges = [
        {
            title: "Today",
            alias: "today",
            period: {
                since: today,
                until: today,
            },
        },
        {
            title: "Yesterday",
            alias: "yesterday",
            period: {
                since: yesterday,
                until: yesterday,
            },
        },
        {
            title: "Last 7 days",
            alias: "last7days",
            period: {
                since: new Date(
                    new Date(new Date().setDate(today.getDate() - 7)).setHours(0, 0, 0, 0)
                ),
                until: yesterday,
            },
        },
        {
            title: "Last 30 days",
            alias: "last30days",
            period: {
                since: new Date(
                    new Date(new Date().setMonth(today.getMonth() - 1)).setHours(0, 0, 0, 0)
                ),
                until: yesterday,
            },
        },
        {
            title: "Last 90 days",
            alias: "last90days",
            period: {
                since: new Date(
                    new Date(new Date().setMonth(today.getMonth() - 3)).setHours(0, 0, 0, 0)
                ),
                until: yesterday,
            },
        },
        {
            title: "Last year",
            alias: "lastyear",
            period: {
                since: new Date(
                    new Date(new Date().setFullYear(today.getFullYear() - 1)).setHours(0, 0, 0, 0)
                ),
                until: yesterday,
            },
        },
        {
            title: "Custom",
            alias: "custom",
            period: {
                since: new Date(),
                until: today,
            },
        },
    ];
    const [popoverActive, setPopoverActive] = useState(false);
    const [activeDateRange, setActiveDateRange] = useState(ranges[0]);
    const [inputValues, setInputValues] = useState({});
    const [{ month, year }, setDate] = useState({
        month: activeDateRange.period.since.getMonth(),
        year: activeDateRange.period.since.getFullYear(),
    });
    const datePickerRef = useRef(null);
    const VALID_YYYY_MM_DD_DATE_REGEX = /^\d{4}-\d{1,2}-\d{1,2}/;
    function isDate(date) {
        return !isNaN(new Date(date).getDate());
    }
    function isValidYearMonthDayDateString(date) {
        return VALID_YYYY_MM_DD_DATE_REGEX.test(date) && isDate(date);
    }
    function isValidDate(date) {
        return date.length === 10 && isValidYearMonthDayDateString(date);
    }
    function parseYearMonthDayDateString(input) {
        const [year, month, day] = input.split("-");
        return new Date(Number(year), Number(month) - 1, Number(day));
    }
    function formatDateToYearMonthDayDateString(date) {
        const year = String(date.getFullYear());
        let month = String(date.getMonth() + 1);
        let day = String(date.getDate());
        if (month.length < 2) {
            month = String(month).padStart(2, "0");
        }
        if (day.length < 2) {
            day = String(day).padStart(2, "0");
        }
        return [year, month, day].join("-");
    }
    function formatDate(date) {
        return formatDateToYearMonthDayDateString(date);
    }
    function nodeContainsDescendant(rootNode, descendant) {
        if (rootNode === descendant) {
            return true;
        }
        let parent = descendant.parentNode;
        while (parent != null) {
            if (parent === rootNode) {
                return true;
            }
            parent = parent.parentNode;
        }
        return false;
    }
    function isNodeWithinPopover(node) {
        return datePickerRef?.current
            ? nodeContainsDescendant(datePickerRef.current, node)
            : false;
    }
    function handleStartInputValueChange(value) {
        setInputValues((prevState) => {
            return { ...prevState, since: value };
        });
        if (isValidDate(value)) {
            const newSince = parseYearMonthDayDateString(value);
            setActiveDateRange((prevState) => {
                const newPeriod =
                    prevState.period && newSince <= prevState.period.until
                        ? { since: newSince, until: prevState.period.until }
                        : { since: newSince, until: newSince };
                return {
                    ...prevState,
                    period: newPeriod,
                };
            });
        }
    }
    function handleEndInputValueChange(value) {
        setInputValues((prevState) => ({ ...prevState, until: value }));
        if (isValidDate(value)) {
            const newUntil = parseYearMonthDayDateString(value);
            setActiveDateRange((prevState) => {
                const newPeriod =
                    prevState.period && newUntil >= prevState.period.since
                        ? { since: prevState.period.since, until: newUntil }
                        : { since: newUntil, until: newUntil };
                return {
                    ...prevState,
                    period: newPeriod,
                };
            });
        }
    }
    function handleInputBlur({ relatedTarget }) {
        const isRelatedTargetWithinPopover =
            relatedTarget != null && isNodeWithinPopover(relatedTarget);
        if (isRelatedTargetWithinPopover) {
            return;
        }
        setPopoverActive(false);
    }
    function handleMonthChange(month, year) {
        setDate({ month, year });
    }
    function handleCalendarChange({ start, end }) {
        const newDateRange = ranges.find((range) => {
            return (
                range.period.since.valueOf() === start.valueOf() &&
                range.period.until.valueOf() === end.valueOf()
            );
        }) || {
            alias: "custom",
            title: "Custom",
            period: {
                since: start,
                until: end,
            },
        };
        setActiveDateRange(newDateRange);
    }
    function apply() {
        setPopoverActive(false);
        setFilterDate(selectedDate);
        getCustomerList(currpage, 'All', sortSelected, protitleValue, activeDateRange.period.since, activeDateRange.period.until);
    }
    function cancel() {
        setActiveDateRange(ranges[0]);
        setPopoverActive(false);
    }
    useEffect(() => {
        if (activeDateRange) {
            setInputValues({
                since: formatDate(activeDateRange.period.since),
                until: formatDate(activeDateRange.period.until),
            });
            function monthDiff(referenceDate, newDate) {
                return (
                    newDate.month -
                    referenceDate.month +
                    12 * (referenceDate.year - newDate.year)
                );
            }
            const monthDifference = monthDiff(
                { year, month },
                {
                    year: activeDateRange.period.until.getFullYear(),
                    month: activeDateRange.period.until.getMonth(),
                }
            );
            if (monthDifference > 1 || monthDifference < 0) {
                setDate({
                    month: activeDateRange.period.until.getMonth(),
                    year: activeDateRange.period.until.getFullYear(),
                });
            }
        }
    }, [activeDateRange]);
    const selectedDate =
        activeDateRange.title === "Custom"
            ? activeDateRange.period.since.toDateString() +
            " - " +
            activeDateRange.period.until.toDateString()
            : activeDateRange.title;

    const tabs = itemStrings.map((item, index) => ({
        content: item,
        index,
        onAction: () => { getCustomerList(currpage, item, sortSelected) },
        id: `${item}-${index}`,
        isLocked: index === 0
    }));

    const sortOptions = [
        { label: 'Added Date', value: 'pv.created asc', directionLabel: 'A-Z' },
        { label: 'Added Date', value: 'pv.created desc', directionLabel: 'Z-A' },
        { label: 'Sent Date', value: 'pv.email_sent_date asc', directionLabel: 'A-Z' },
        { label: 'Sent Date', value: 'pv.email_sent_date desc', directionLabel: 'Z-A' },
    ];
    const [sortSelected, setSortSelected] = useState(['pv.created asc']);
    const { mode, setMode } = useSetIndexFiltersMode();
    const onHandleCancel = () => {
        setProtitleValue('');
        setEmailVal(undefined);
        setFilterDate(undefined);
        setActiveDateRange(ranges[0]);
    };

    const handleEmailRemove = useCallback(
        () => setEmailVal(undefined),
        [],
    );
    const handleDateRemove = useCallback(() => {
        setFilterDate(undefined);
        setActiveDateRange(ranges[0]);
    }, []);
    const handleFiltersClearAll = useCallback(() => {
        handleEmailRemove();
        handleDateRemove();
        getCustomerList(currpage, 'All', sortSelected, protitleValue);
    }, [handleEmailRemove, handleDateRemove]);

    const filters = [
        {
            key: 'email',
            label: 'Email',
            filter: (
                <TextField
                    label="Email"
                    autoComplete="off"
                    titleHidden
                    value={emailVal}
                    selected={emailVal}
                    onChange={(value) => setEmailVal(value)}
                />
            ),
            shortcut: true,
        },
        {
            key: 'date',
            label: 'Sent Date',
            filter: (
                <Popover
                    active={popoverActive}
                    autofocusTarget="none"
                    preferredAlignment="left"
                    preferredPosition="below"
                    fluidContent
                    sectioned={false}
                    fullHeight
                    activator={
                        <Button
                            size="slim"
                            icon={CalendarMinor}
                            onClick={() => setPopoverActive(!popoverActive)}
                        >
                            {selectedDate}
                        </Button>
                    }
                    onClose={() => setPopoverActive(false)}
                >
                    <Popover.Pane fixed>
                        <HorizontalGrid
                            columns={{
                                xs: "1fr",
                                mdDown: "1fr",
                                md: "max-content max-content",
                            }}
                            gap={0}
                            ref={datePickerRef}
                        >
                            <Box
                                maxWidth={mdDown ? "516px" : "212px"}
                                width={mdDown ? "100%" : "212px"}
                                padding={{ xs: 500, md: 0 }}
                                paddingBlockEnd={{ xs: 100, md: 0 }}
                            >
                                {mdDown ? (
                                    <Select
                                        label="dateRangeLabel"
                                        labelHidden
                                        onChange={(value) => {
                                            const result = ranges.find(
                                                ({ title, alias }) => title === value || alias === value
                                            );
                                            setActiveDateRange(result);
                                        }}
                                        value={activeDateRange?.title || activeDateRange?.alias || ""}
                                        options={ranges.map(({ alias, title }) => title || alias)}
                                    />
                                ) : (
                                    <Scrollable style={{ height: "334px" }}>
                                        <OptionList
                                            options={ranges.map((range) => ({
                                                value: range.alias,
                                                label: range.title,
                                            }))}
                                            selected={activeDateRange.alias}
                                            onChange={(value) => {
                                                setActiveDateRange(
                                                    ranges.find((range) => range.alias === value[0])
                                                );
                                            }}
                                        />
                                    </Scrollable>
                                )}
                            </Box>
                            <Box padding={{ xs: 500 }} maxWidth={mdDown ? "320px" : "516px"}>
                                <VerticalStack gap="400">
                                    <HorizontalStack gap="200">
                                        <div style={{ flexGrow: 1 }}>
                                            <TextField
                                                role="combobox"
                                                label={"Since"}
                                                labelHidden
                                                prefix={<Icon source={CalendarMinor} />}
                                                value={inputValues.since}
                                                onChange={handleStartInputValueChange}
                                                onBlur={handleInputBlur}
                                                autoComplete="off"
                                            />
                                        </div>
                                        <Icon source={ArrowRightMinor} />
                                        <div style={{ flexGrow: 1 }}>
                                            <TextField
                                                role="combobox"
                                                label={"Until"}
                                                labelHidden
                                                prefix={<Icon source={CalendarMinor} />}
                                                value={inputValues.until}
                                                onChange={handleEndInputValueChange}
                                                onBlur={handleInputBlur}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </HorizontalStack>
                                    <div>
                                        <DatePicker
                                            month={month}
                                            year={year}
                                            selected={{
                                                start: activeDateRange.period.since,
                                                end: activeDateRange.period.until,
                                            }}
                                            onMonthChange={handleMonthChange}
                                            onChange={handleCalendarChange}
                                            multiMonth={shouldShowMultiMonth}
                                            allowRange
                                        />
                                    </div>
                                </VerticalStack>
                            </Box>
                        </HorizontalGrid>
                    </Popover.Pane>
                    <Popover.Pane fixed>
                        <Popover.Section>
                            <HorizontalStack align="end">
                                <Button onClick={cancel}>Cancel</Button>
                                <div className='apply_btn'>
                                    <Button primary onClick={apply}>
                                        Apply
                                    </Button>
                                </div>
                            </HorizontalStack>
                        </Popover.Section>
                    </Popover.Pane>
                </Popover>
            ),
            shortcut: true,
        }
    ];

    if (emailVal && !isEmpty(emailVal)) {
        const key = 'email';
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, emailVal),
            onRemove: handleEmailRemove,
        });
    }
    if (filterDate && !isEmpty(filterDate)) {
        const key = 'date';
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, filterDate),
            onRemove: handleDateRemove,
        });
    }

    function disambiguateLabel(key, value) {
        switch (key) {
            case 'email':
                return `Email is ${value}`;
            case 'date':
                return `Sent date is ${value}`;
            default:
                return value;
        }
    }

    function isEmpty(value) {
        if (Array.isArray(value)) {
            return value.length === 0;
        } else {
            return value === '' || value == null;
        }
    }

    const resourceName = {
        singular: 'Customer',
        plural: 'customer',
    };
    const { selectedResources, handleSelectionChange } = useIndexResourceState(customer);

    const promotedBulkActions = [
        {
            content: 'Delete notification',
            onAction: () => deleteNotification(selectedResources),
        }
    ];
    const toggleActive = useCallback(
        () => setActiveEnable((activeEnable) => !activeEnable),
        []
    );

    const toastMarkup = activeEnable ? (
        <Toast
            duration={5000}
            content={toastMsg}
            onDismiss={toggleActive}
        />
    ) : null;

    const deleteNotification = async (ids) => {
        let response = await ApiCall("DELETE", `/delete_notification`, { ids: ids }, header);
        if (response?.data?.statusCode === 200) {
            setToastMsg(response?.data?.message);
            navigate(0);
        }
    }

    const getCustomerList = async (page, tabVal, sort, searchVal, since, until) => {
        page = (page) ? page : 1;
        setSortSelected(sort);
        let params = '';
        if (searchVal && searchVal.length) {
            params += `&search=${searchVal}`;
        }
        if (emailVal && emailVal.length >= 3) {
            params += `&filter_email=${emailVal}`;
        }
        if (since && until) {
            since = formatDate(since);
            until = formatDate(until);
            params += `&since=${since}&until=${until}`;
        }
        const res = await GetApiCall('GET', `/get_customer?tab=${tabVal}&page=${page}&sort=${sort}${params}`, header);
        if (res?.data?.statusCode === 200) {
            const getCustData = res.data.data.customer;
            setCustomer(getCustData);
            setCurrPage(page);
            settotalPage(res.data.data.totalpage)
        }
    }

    const rows = customer.map(({ id, email, marketing_status, product_title, variant_title, created, sent_date, order_create_status }, index) => (
        <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
            <IndexTable.Cell>
                <div className='display-email'>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M1.5 0C1.10218 0 0.720644 0.158035 0.43934 0.43934C0.158035 0.720644 0 1.10218 0 1.5V10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10218 12 1.5 12H14.5C14.8978 12 15.2794 11.842 15.5607 11.5607C15.842 11.2794 16 10.8978 16 10.5V1.5C16 1.10218 15.842 0.720644 15.5607 0.43934C15.2794 0.158035 14.8978 0 14.5 0H1.5ZM3.515 2.143C3.40239 2.07253 3.27687 2.0252 3.14577 2.00379C3.01466 1.98237 2.88061 1.98728 2.75142 2.01825C2.62224 2.04921 2.50052 2.10561 2.39338 2.18413C2.28623 2.26266 2.19581 2.36175 2.12738 2.47562C2.05896 2.58948 2.01391 2.71583 1.99486 2.8473C1.97581 2.97877 1.98315 3.11272 2.01644 3.24132C2.04973 3.36992 2.10831 3.4906 2.18876 3.59631C2.26921 3.70202 2.36992 3.79064 2.485 3.857L7.485 6.857C7.64053 6.95044 7.81856 6.99981 8 6.99981C8.18144 6.99981 8.35947 6.95044 8.515 6.857L13.515 3.857C13.6301 3.79064 13.7308 3.70202 13.8112 3.59631C13.8917 3.4906 13.9503 3.36992 13.9836 3.24132C14.0169 3.11272 14.0242 2.97877 14.0051 2.8473C13.9861 2.71583 13.941 2.58948 13.8726 2.47562C13.8042 2.36175 13.7138 2.26266 13.6066 2.18413C13.4995 2.10561 13.3778 2.04921 13.2486 2.01825C13.1194 1.98728 12.9853 1.98237 12.8542 2.00379C12.7231 2.0252 12.5976 2.07253 12.485 2.143L8 4.833L3.515 2.143Z" fill="#5C5F62" />
                    </svg>
                    <div>{email}</div>
                </div>
            </IndexTable.Cell>
            <IndexTable.Cell>
                {(marketing_status === '0') ?
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2ZM7.707 6.293C7.5184 6.11084 7.2658 6.01005 7.0036 6.01233C6.7414 6.0146 6.49059 6.11977 6.30518 6.30518C6.11977 6.49059 6.0146 6.7414 6.01233 7.0036C6.01005 7.2658 6.11084 7.5184 6.293 7.707L8.586 10L6.293 12.293C6.19749 12.3852 6.12131 12.4956 6.0689 12.6176C6.01649 12.7396 5.9889 12.8708 5.98775 13.0036C5.9866 13.1364 6.0119 13.2681 6.06218 13.391C6.11246 13.5139 6.18671 13.6255 6.2806 13.7194C6.3745 13.8133 6.48615 13.8875 6.60905 13.9378C6.73194 13.9881 6.86362 14.0134 6.9964 14.0123C7.12918 14.0111 7.2604 13.9835 7.3824 13.9311C7.50441 13.8787 7.61475 13.8025 7.707 13.707L10 11.414L12.293 13.707C12.3852 13.8025 12.4956 13.8787 12.6176 13.9311C12.7396 13.9835 12.8708 14.0111 13.0036 14.0123C13.1364 14.0134 13.2681 13.9881 13.391 13.9378C13.5139 13.8875 13.6255 13.8133 13.7194 13.7194C13.8133 13.6255 13.8875 13.5139 13.9378 13.391C13.9881 13.2681 14.0134 13.1364 14.0123 13.0036C14.0111 12.8708 13.9835 12.7396 13.9311 12.6176C13.8787 12.4956 13.8025 12.3852 13.707 12.293L11.414 10L13.707 7.707C13.8892 7.5184 13.99 7.2658 13.9877 7.0036C13.9854 6.7414 13.8802 6.49059 13.6948 6.30518C13.5094 6.11977 13.2586 6.0146 12.9964 6.01233C12.7342 6.01005 12.4816 6.11084 12.293 6.293L10 8.586L7.707 6.293Z" fill="#D82C0D" /></svg> :
                    <svg width="17" height="17" viewBox="0 0 512 512"><path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" fill="#189e18"></path></svg>}
            </IndexTable.Cell>
            <IndexTable.Cell>{product_title}</IndexTable.Cell>
            <IndexTable.Cell>{(variant_title === '') ? '-' : variant_title}</IndexTable.Cell>
            <IndexTable.Cell>{created}</IndexTable.Cell>
            <IndexTable.Cell>{(sent_date) ? sent_date : '-'}</IndexTable.Cell>
            <IndexTable.Cell>{
                (order_create_status === '1') ? <div className='customer-badge'><Badge tone="info">Purchase</Badge></div> : '-'}
            </IndexTable.Cell>
        </IndexTable.Row>
    ));

    useEffect(() => {
        if (storeInfo) {
            if (protitleValue.length >= 3) {
                getCustomerList(currpage, 'All', sortSelected, protitleValue);
            } else {
                getCustomerList(currpage, 'All', sortSelected, '');
            }
        }
    }, [storeInfo, sortSelected, protitleValue, emailVal, activeDateRange]);

    return (
        <>
            {window.location.hostname !== "localhost" ? <>
                <AppProvider>
                    <AppBridgeProvider config={config}>
                        <TitleBar title={"Customer"} />
                        <Page fullWidth>
                            <LegacyCard>
                                <IndexFilters
                                    sortOptions={sortOptions}
                                    sortSelected={sortSelected}
                                    queryValue={protitleValue}
                                    queryPlaceholder="Search product title"
                                    onQueryChange={(value) => setProtitleValue(value)}
                                    onQueryClear={() => setProtitleValue('')}
                                    onSort={setSortSelected}
                                    cancelAction={{
                                        onAction: onHandleCancel,
                                        disabled: false,
                                        loading: false,
                                    }}
                                    tabs={tabs}
                                    selected={selected}
                                    onSelect={setSelected}
                                    filters={filters}
                                    appliedFilters={appliedFilters}
                                    onClearAll={handleFiltersClearAll}
                                    mode={mode}
                                    setMode={setMode}
                                />
                                <IndexTable
                                    resourceName={resourceName}
                                    itemCount={rows.length}
                                    selectedItemsCount={
                                        selectedResources.length
                                    }
                                    onSelectionChange={handleSelectionChange}
                                    headings={[
                                        { title: "Email / Phone" },
                                        { title: "Marketing Accepted?" },
                                        { title: "Product title" },
                                        { title: "Variant" },
                                        { title: "Added" },
                                        { title: "Sent" },
                                        { title: "Purchase" }
                                    ]}
                                    promotedBulkActions={promotedBulkActions}
                                >
                                    {rows}
                                </IndexTable>
                                {totalpage > 1 ? (
                                    <div className='pagination'>
                                        <Pagination
                                            hasPrevious={currpage === 1 || currpage > totalpage ? false : true}
                                            onPrevious={() => {
                                                getCustomerList(currpage - 1, 'All', sortSelected, protitleValue);
                                            }}
                                            previousTooltip="Previous"
                                            hasNext={totalpage === currpage ? false : true}
                                            nextTooltip="Next"
                                            onNext={() => {
                                                getCustomerList(currpage + 1, 'All', sortSelected, protitleValue);
                                            }}
                                        />
                                    </div>
                                ) : ''}
                            </LegacyCard>
                            {toastMarkup}
                            <Footer />
                        </Page>
                    </AppBridgeProvider>
                </AppProvider>
            </> : <>
                <Page fullWidth>
                    <LegacyCard>
                        <IndexFilters
                            sortOptions={sortOptions}
                            sortSelected={sortSelected}
                            queryValue={protitleValue}
                            queryPlaceholder="Search product title"
                            onQueryChange={(value) => setProtitleValue(value)}
                            onQueryClear={() => setProtitleValue('')}
                            onSort={setSortSelected}
                            cancelAction={{
                                onAction: onHandleCancel,
                                disabled: false,
                                loading: false,
                            }}
                            tabs={tabs}
                            selected={selected}
                            onSelect={setSelected}
                            filters={filters}
                            appliedFilters={appliedFilters}
                            onClearAll={handleFiltersClearAll}
                            mode={mode}
                            setMode={setMode}
                        />
                        <IndexTable
                            resourceName={resourceName}
                            itemCount={rows.length}
                            selectedItemsCount={
                                selectedResources.length
                            }
                            onSelectionChange={handleSelectionChange}
                            headings={[
                                { title: "Email / Phone" },
                                { title: "Marketing Accepted?" },
                                { title: "Product title" },
                                { title: "Variant" },
                                { title: "Added" },
                                { title: "Sent" },
                                { title: "Purchase" }
                            ]}
                            promotedBulkActions={promotedBulkActions}
                        >
                            {rows}
                        </IndexTable>
                        {totalpage > 1 ? (
                            <div className='pagination'>
                                <Pagination
                                    hasPrevious={currpage === 1 || currpage > totalpage ? false : true}
                                    onPrevious={() => {
                                        getCustomerList(currpage - 1, 'All', sortSelected, protitleValue);
                                    }}
                                    previousTooltip="Previous"
                                    hasNext={totalpage === currpage ? false : true}
                                    nextTooltip="Next"
                                    onNext={() => {
                                        getCustomerList(currpage + 1, 'All', sortSelected, protitleValue);
                                    }}
                                />
                            </div>
                        ) : ''}
                    </LegacyCard>
                    {toastMarkup}
                    <Footer />
                </Page>
            </>}
        </>
    );
}

export default Customer;