import React, { useCallback, useState, useEffect } from 'react';
import { ChoiceList, IndexFilters, LegacyCard, Page, useSetIndexFiltersMode, Pagination, IndexTable, AppProvider } from '@shopify/polaris';
import { useSelector } from 'react-redux';
import { GetApiCall } from '../helper/axios';
import {
  Provider as AppBridgeProvider,
  TitleBar,
} from "@shopify/app-bridge-react";
import { config } from "../helper/commonApi";
import Footer from './Footer';

const ProductFilter = () => {
  const clientStoreData = useSelector(state => state.clientStoreData);
  const storeInfo = clientStoreData.clientInfo?.storeInfo;
  const header = { authentication: storeInfo?.token };
  const [currpage, setCurrPage] = useState(1);
  const [totalpage, settotalPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [Product_queryValue, Product_setQueryValue] = useState('');
  const [Product_sortSelected, Product_setSortSelected] = useState(['p.product_title asc']);
  const [productTitle, setProductTitle] = useState(['']);
  const [vendorname, setvendorname] = useState(['']);
  const [proTitleVal, setproTitleVal] = useState([]);
  const [verdorVal, setverdorVal] = useState([]);
  const { mode, setMode } = useSetIndexFiltersMode();

  const Product_sortOptions = [
    { label: 'Product title', value: 'p.product_title asc', directionLabel: 'A-Z' },
    { label: 'Product title', value: 'p.product_title desc', directionLabel: 'Z-A' },
    { label: 'Last added', value: 'pv.created asc', directionLabel: 'A-Z' },
    { label: 'Last added', value: 'pv.created desc', directionLabel: 'Z-A' },
    { label: 'Last sent', value: 'pv.updated asc', directionLabel: 'A-Z' },
    { label: 'Last sent', value: 'pv.updated desc', directionLabel: 'Z-A' }
  ];

  const getproductList = async (direction, page, searchVal) => {
    page = (page) ? page : 1;
    Product_setSortSelected(direction);
    let params = '';
    if (searchVal && searchVal.length) {
      params += `&search=${searchVal}`;
    }
    if (proTitleVal && proTitleVal.length > 0) {
      params += `&filter=${proTitleVal}`;
    }
    if (verdorVal && verdorVal.length > 0) {
      params += `&filter_var=${verdorVal}`;
    }
    const res = await GetApiCall('GET', `/get_products?sort=${direction}&page=${page}${params}`, header);
    if (res?.data?.statusCode === 200) {
      const getSubData = res.data.data.product;
      const getTitle = res.data.data.getTitle;
      const getvendorname = res.data.data.getvendor;
      let rows = [];
      let titleRows = [];
      let vtitleRows = [];
      if (getSubData.length > 0) {
        rows = getSubData.map(({ product_id, product_title, variant_id, variant_title, product_vendor, sku, unsent, sent, created, email_sent_date }, index) => (
          <IndexTable.Row id={variant_id} key={variant_id} position={index}>
            <IndexTable.Cell>{product_id}</IndexTable.Cell>
            <IndexTable.Cell>{product_title}</IndexTable.Cell>
            <IndexTable.Cell>{variant_id}</IndexTable.Cell>
            <IndexTable.Cell>{(variant_title === '' ? '-' : variant_title)}</IndexTable.Cell>
            <IndexTable.Cell>{product_vendor}</IndexTable.Cell>
            <IndexTable.Cell>{(sku === '') ? '-' : sku}</IndexTable.Cell>
            <IndexTable.Cell>{unsent}</IndexTable.Cell>
            <IndexTable.Cell>{sent}</IndexTable.Cell>
            <IndexTable.Cell>{(unsent + sent)}</IndexTable.Cell>
            <IndexTable.Cell>{created}</IndexTable.Cell>
            <IndexTable.Cell>{email_sent_date}</IndexTable.Cell>
          </IndexTable.Row>
        ));
        titleRows = getTitle && getTitle.map(({ product_title }) => (
          { label: product_title, value: product_title }
        ));
        vtitleRows = getvendorname && getvendorname.map(({ product_vendor }) => (
          { label: product_vendor, value: product_vendor }
        ));
      }
      setvendorname(vtitleRows);
      setProductTitle(titleRows);
      setRows(rows);
      setCurrPage(page);
      settotalPage(res.data.data.totalpage)
    }
  }

  useEffect(() => {
    if (storeInfo) {
      getproductList([]);
    }
  }, [storeInfo]);

  useEffect(() => {
    if (Product_queryValue.length >= 3) {
      getproductList(Product_sortSelected, currpage, Product_queryValue);
    } else {
      getproductList(Product_sortSelected, 1, '');
    }
  }, [Product_queryValue, Product_sortSelected, proTitleVal, verdorVal]);

  const Product_onHandleCancel = () => {
    Product_setQueryValue('');
    getproductList(Product_sortSelected, currpage, '');
  };

  const handleproducttitleRemove = useCallback(
    () => setproTitleVal(undefined),
    [],
  );
  const handlevendornameRemove = useCallback(
    () => setverdorVal(undefined),
    [],
  );
  const Product_handleFiltersClearAll = useCallback(() => {
    handleproducttitleRemove();
    handlevendornameRemove();
  }, [
    handleproducttitleRemove,
    handlevendornameRemove
  ]);

  const Product_filters = [
    {
      key: 'productname',
      label: 'Product title',
      filter: (
        <ChoiceList
          title="Product title"
          titleHidden
          choices={productTitle}
          selected={proTitleVal || []}
          onChange={(value) => setproTitleVal(value)}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: 'vendorname',
      label: 'Vendor name',
      filter: (
        <ChoiceList
          title="Vendor name"
          titleHidden
          choices={vendorname}
          selected={verdorVal || []}
          onChange={(value) => setverdorVal(value)}
          allowMultiple
        />
      ),
      shortcut: true,
    },
  ];

  const Product_appliedFilters = [];
  if (proTitleVal && !isEmpty(proTitleVal)) {
    const key = 'productname';
    Product_appliedFilters.push({
      key,
      label: disambiguateLabel(key, proTitleVal),
      onRemove: handleproducttitleRemove,
    });
  }
  if (verdorVal && !isEmpty(verdorVal)) {
    const key = 'vendorname';
    Product_appliedFilters.push({
      key,
      label: disambiguateLabel(key, verdorVal),
      onRemove: handlevendornameRemove,
    });
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case 'vendorname':
        return (value).map((val) => `Vendor ${val}`).join(', ');
      case 'productname':
        return (value).map((val) => `Product ${val}`).join(', ');
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

  const resourceName = {
    singular: 'product',
    plural: 'product',
  };

  return (
    <>
      {window.location.hostname !== "localhost" ? <>
        <AppProvider>
          <AppBridgeProvider config={config}>
            <TitleBar title={"Product"} />
            <Page fullWidth>
              <div className='product'>
                <LegacyCard>
                  <IndexFilters
                    sortOptions={Product_sortOptions}
                    sortSelected={Product_sortSelected}
                    queryValue={Product_queryValue}
                    queryPlaceholder="Search product title"
                    onQueryChange={(value) => Product_setQueryValue(value)}
                    onQueryClear={() => { Product_setQueryValue([]) }}
                    onSort={Product_setSortSelected}
                    cancelAction={{
                      onAction: Product_onHandleCancel,
                      disabled: true,
                      loading: false,
                    }}
                    tabs={[]}
                    filters={Product_filters}
                    appliedFilters={Product_appliedFilters}
                    onClearAll={Product_handleFiltersClearAll}
                    mode={mode}
                    setMode={setMode}
                  />
                  <IndexTable
                    resourceName={resourceName}
                    itemCount={rows.length}
                    headings={[
                      { title: 'Product id' },
                      { title: 'Product title' },
                      { title: 'Variant id' },
                      { title: 'Variant' },
                      { title: 'Vendor name' },
                      { title: 'Sku' },
                      { title: 'Unsent' },
                      { title: 'Sent' },
                      // { title: 'Archived' },
                      { title: 'Total' },
                      { title: 'Last added' },
                      { title: 'Last Sent' },
                    ]}
                    selectable={false}
                  >
                    {rows}
                  </IndexTable>
                  {totalpage > 1 ? (
                    <div className='pagination'>
                      <Pagination
                        hasPrevious={currpage === 1 || currpage > totalpage ? false : true}
                        onPrevious={() => {
                          getproductList(Product_sortSelected, currpage - 1, Product_queryValue);
                        }}
                        previousTooltip="Previous"
                        hasNext={totalpage === currpage ? false : true}
                        nextTooltip="Next"
                        onNext={() => {
                          getproductList(Product_sortSelected, currpage + 1, Product_queryValue);
                        }}
                      />
                    </div>
                  ) : ''}
                </LegacyCard>
              </div>
              <Footer />
            </Page>
          </AppBridgeProvider>
        </AppProvider>
      </> : <>
        <Page fullWidth>
          <div className='product'>
            <LegacyCard>
              <IndexFilters
                sortOptions={Product_sortOptions}
                sortSelected={Product_sortSelected}
                queryValue={Product_queryValue}
                queryPlaceholder="Search product title"
                onQueryChange={(value) => Product_setQueryValue(value)}
                onQueryClear={() => { Product_setQueryValue([]) }}
                onSort={Product_setSortSelected}
                cancelAction={{
                  onAction: Product_onHandleCancel,
                  disabled: true,
                  loading: false,
                }}
                tabs={[]}
                filters={Product_filters}
                appliedFilters={Product_appliedFilters}
                onClearAll={Product_handleFiltersClearAll}
                mode={mode}
                setMode={setMode}
              />
              <IndexTable
                resourceName={resourceName}
                itemCount={rows.length}
                headings={[
                  { title: 'Product id' },
                  { title: 'Product title' },
                  { title: 'Variant id' },
                  { title: 'Variant' },
                  { title: 'Vendor name' },
                  { title: 'Sku' },
                  { title: 'Unsent' },
                  { title: 'Sent' },
                  // { title: 'Archived' },
                  { title: 'Total' },
                  { title: 'Last added' },
                  { title: 'Last Sent' },
                ]}
                selectable={false}
              >
                {rows}
              </IndexTable>
              {totalpage > 1 ? (
                <div className='pagination'>
                  <Pagination
                    hasPrevious={currpage === 1 || currpage > totalpage ? false : true}
                    onPrevious={() => {
                      getproductList(Product_sortSelected, currpage - 1, Product_queryValue);
                    }}
                    previousTooltip="Previous"
                    hasNext={totalpage === currpage ? false : true}
                    nextTooltip="Next"
                    onNext={() => {
                      getproductList(Product_sortSelected, currpage + 1, Product_queryValue);
                    }}
                  />
                </div>
              ) : ''}
            </LegacyCard>
          </div>
          <Footer />
        </Page>
      </>}
    </>
  )
}

export default ProductFilter;